import { Link, useHistory } from 'react-router-dom';
import { abbreviate } from '../../../helpers';
import { useCheckoutStore } from '../lib/store/checkout';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { checkoutJobSchema } from '../lib/schema';
import { lang } from '../../../lang';

const StepFourZero = () => {
  const { checkoutData, commonJob } = useCheckoutStore()
  const location = useHistory();
  const { getValues, setValue, watch } = useFormContext<z.infer<typeof checkoutJobSchema>>()
  const { id } = getValues()
  const { referral_fee, hiring_bonus, referral_count, blue_collar } = watch()


  const min = blue_collar === "blue_collar" ? 3 : 2
  const defaultValue = blue_collar === "blue_collar" ? 100 : 500

  const onIncrement = (key: "referral_fee" | "hiring_bonus" | "referral_count") => {
    const { referral_fee, referral_count, hiring_bonus } = getValues()
    if (key === "referral_fee") {
      setValue("referral_fee", referral_fee + defaultValue)
    } else if (key === "hiring_bonus") {
      setValue("hiring_bonus", hiring_bonus + defaultValue)
    } else if (key === "referral_count") {
      setValue("referral_count", referral_count + min)
    }
  };

  const onDecrement = (key: "referral_fee" | "hiring_bonus" | "referral_count") => {
    const { referral_fee, referral_count, hiring_bonus } = getValues()
    console.log(referral_fee , hiring_bonus , (defaultValue * 2))
    // if (!((referral_fee + hiring_bonus) - (defaultValue * 3))) {
    //   setValue("referral_count", min)
    // }
    if (key === "referral_fee") {
      referral_fee > defaultValue && setValue("referral_fee", referral_fee - defaultValue)
    } else if (key === "hiring_bonus") {
      hiring_bonus > defaultValue && setValue("hiring_bonus", hiring_bonus - defaultValue)
    } else if (key === "referral_count") {
      ((referral_count - min) >= (min)) && setValue("referral_count", referral_count - min)
    }
  };

  return !checkoutData ? <></> : (
    <div className="flex flex-col h-full p-6 px-8">
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-base font-medium capitalize text-pj-green-200">
          New Job
        </div>
      </div>
      <div className="flex flex-col items-start w-full gap-5 mt-4 rounded-lg">
        <div
          className={`w-24 h-24 bg-cover rounded-full bg-left flex justify-center items-center text-gray-500 ${(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
            }`}
          style={{ backgroundImage: `url(${checkoutData.company_image})` }}
        >
          {(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            abbreviate(checkoutData.company_name ?? '')}
        </div>
        <div className="flex flex-row gap-1 text-lg font-semibold text-pj-blue-500"><div>{checkoutData.job_title}</div> - <div>{checkoutData.company_name}</div></div>
      </div>
      <div className="w-full h-1 my-6 bg-pj-blue-100"></div>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-row items-center justify-between'>
          <div className='text-lg font-semibold text-pj-blue-100'>Referral Fee</div>
          <div className="relative flex flex-row h-10 gap-1">
            <IconButton disabled={defaultValue >= referral_fee} onClick={() => onDecrement("referral_fee")} icon={<span className="m-auto">-</span>} />
            <div className="flex items-center justify-center w-20 text-xs bg-white border border-gray-400 rounded cursor-default text-pj-blue-100 md:text-base">
              <span>{referral_fee}</span>
            </div>
            <IconButton onClick={() => onIncrement("referral_fee")} icon={<span className="m-auto">+</span>} />
          </div>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='text-lg font-semibold text-pj-blue-100'>Hiring Bonus</div>
          <div className="relative flex flex-row h-10 gap-1">
            <IconButton disabled={defaultValue >= hiring_bonus} onClick={() => onDecrement("hiring_bonus")} icon={<span className="m-auto">-</span>} />
            <div className="flex items-center justify-center w-20 text-xs bg-white border border-gray-400 rounded cursor-default text-pj-blue-100 md:text-base">
              <span>{hiring_bonus}</span>
            </div>
            <IconButton onClick={() => onIncrement("hiring_bonus")} icon={<span className="m-auto">+</span>} />
          </div>
        </div>
        <div className='flex flex-row items-center justify-between'>
          <div className='text-lg font-semibold text-pj-blue-100'>Number of Openings</div>
          <div className="relative flex flex-row h-10 gap-1">
            <IconButton disabled={min >= referral_count} onClick={() => onDecrement("referral_count")} icon={<span className="m-auto">-</span>} />
            <div className="flex items-center justify-center w-20 text-xs bg-white border border-gray-400 rounded cursor-default text-pj-blue-100 md:text-base">
              <span>{referral_count}</span>
            </div>
            <IconButton disabled={(defaultValue >= hiring_bonus) && (defaultValue >= referral_fee) && false} onClick={() => onIncrement("referral_count")} icon={<span className="m-auto">+</span>} />
          </div>
        </div>
      </div>
      <div className="w-full h-1 my-6 bg-pj-blue-500"></div>
      <div className='flex flex-row items-center justify-between text-lg font-semibold text-pj-blue-100'>
        <p>POSTING FEE SUBTOTAL</p>
        <p>
          {lang.formatString(
            lang.general.php_total,
            ((referral_fee + hiring_bonus) * referral_count).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </p>
      </div>
      <p className='text-sm italic font-thin text-red-500'>*subject to admin fee and VAT</p>
      <div className="flex flex-row items-end self-end justify-end flex-1">
        <Link to={{
          pathname: `/checkout/4/1/${id}`,
          state: { prev: location.location.pathname },
        }}>
          <div
            className={`px-8 py-2 rounded-full cursor-pointer inline-block text-base bg-pj-green-200 text-white`}
          >
            Next
          </div>
        </Link>
      </div>
    </div>
  );
};

const IconButton = ({ onClick, disabled, icon }: { onClick: any; disabled?: boolean, icon: JSX.Element }) => {
  return <button
    type="button"
    className={`flex flex-none w-10 h-full font-semibold text-white rounded cursor-pointer focus:outline-none ${disabled ? 'bg-gray-300 cursor-default' : 'bg-pj-blue-100  border border-gray-400'}`}
    onClick={() => !disabled && onClick("referral_count")}
  >
    {icon}
  </button>
}

export default StepFourZero;
