"use client"
import { Card, Button, Avatar, Typography, Badge } from "antd"
import { CheckOutlined, UserAddOutlined } from "@ant-design/icons"
import { useState } from "react"
import { inviteToApply } from "../../../utilities/api_v2/email/board"
import { useHistory } from "react-router-dom"

const { Title, Text } = Typography

interface MatchProps {
  seeker_id:number,
  job_id:number
  initials: string
  full_name: string
  email: string
  job_title: string
  matched_score: number
  invited: boolean
  status: string
}

export default function MatchesUI({matches} : {matches: MatchProps[]}) {
  const [loadingStates, setLoadingStates] = useState<{ [key: number]: boolean }>({})
    const history = useHistory();

  const getInitials = (name: string) => {
    return name.substring(0, 2).toUpperCase()
  }

  const RedactString = ( text, visibleChars = 8 ) => {
    if (!text || visibleChars < 0 || visibleChars > text.length) {
      return <span>{text}</span>; // Return original text if parameters are invalid
    }
  
    const redactedPart = "*".repeat(text.length - visibleChars);
    const redactedText = text.substring(0, visibleChars) + redactedPart;
  
    return redactedText;
  };

  const handleRedirect = (match:MatchProps) => {
    history.push(`/job-matched-profile/${match.seeker_id}/${match.job_id}`)
  }

  const handleInvite = async (match: MatchProps) => {
    setLoadingStates((prev) => ({ ...prev, [match.seeker_id]: true }))

    // Simulate API call
    try {
      await new Promise((resolve) => setTimeout(resolve, 1500))
       const payload = {
          job_id: match.job_id,
          seeker_id: match.seeker_id,
      }
      
          await inviteToApply(payload).then(() => {
            match.status = 'Invited'
          })
    } catch (error) {
    } finally {
      setLoadingStates((prev) => ({ ...prev, [match.seeker_id]: false }))
    }
  }

  return (
    <div className="container mx-auto py-4 px-4">
      <div className="flex items-center gap-2 mb-4">
        <Title level={4} className="!m-0">
          Matches
        </Title>
        <Badge color="#FF9771" count={matches.length} className="bg-gray-100 text-pj-orange-200" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
        {matches.map((match, key) => (
          <Card 
            key={key} 
            className="overflow-hidden" 
            bodyStyle={{ padding: 16 }} size="small" 
          >
            <div className="cursor-pointer" onClick={() => handleRedirect(match)}>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-3">
                  {/* <div
                    className={`flex items-center justify-center w-8 h-8 text-sm font-bold text-white rounded-md ${
                      key <= 2 ? "bg-pj-orange-200" : "bg-black"
                    }`}
                  >
                    {key+1}
                  </div> */}
                  <div className="flex items-center gap-3">
                    <Avatar
                      size={40}
                      className="bg-gray-100 text-black flex items-center justify-center text-base font-medium"
                    >
                      {getInitials(match.full_name)}
                    </Avatar>
                    <div>
                      <Text strong className="text-base block leading-tight">
                        {match.full_name}
                      </Text>
                      <Text type="secondary" className="text-sm">
                      </Text>
                    </div>
                  </div>
                </div>
                {/* <div className="flex items-center justify-center w-12 h-8 text-sm font-medium bg-gray-100 rounded-lg">
                  {match.matched_score}
                </div> */}
              </div>
              <div className="flex items-center justify-between mt-3">
                <Text className="text-sm">{match.job_title}</Text>
                {match.status !== 'pending' ? (
                  <div className="flex items-center gap-1.5 px-3 py-1.5 text-sm text-green-600 border border-green-200 rounded-lg">
                    <CheckOutlined className="text-xs" />
                    <span>{match.status}</span>
                  </div>
                ) : (
                  <Button
                    type="primary"
                    icon={<UserAddOutlined />}
                    loading={loadingStates[match.seeker_id]}
                    onClick={() => handleInvite(match)}
                    size="small"
                    className="flex items-center gap-1.5 bg-pj-green-200 hover:bg-pj-orange-200"
                  >
                    Invite
                  </Button>
                )}
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  )
}

