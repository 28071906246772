import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideIn } from '../../framer';
import { checkoutJobV2 } from '../../utilities/api_v2/checkout';
import { useCheckoutStore } from './lib/store/checkout';
import JobSteps from '../job-create-v2/components/step';
import { CircularProgress } from '@material-ui/core';
import { AiOutlineClose } from 'react-icons/ai';
import { CheckoutDataFetched } from '../../types/checkout';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { checkoutJobSchema } from './lib/schema';
import { defaultCheckoutData } from './lib/store/const';
import { getEmployeeName } from '../../utilities/api_v2/dashboard';
import { auth } from '../../utilities/auth';

type Params = {
  step: string;
  sub: string;
  job_id: string;
};

const CheckoutV2 = () => {

  const {
    pages,
    setCheckoutData,
    setCheckoutJobDataFetched,
    checkoutJobDataFetched,
    checkoutJobDataIsLoading,
    setCheckoutJobDataIsLoading,
    commonJob
  } = useCheckoutStore()
  const { step, sub, job_id }: Params = useParams();
  const history = useHistory();
  const [prev, setPrev] = useState<string>('');
  const location: any = useLocation();

  const form = useForm<z.infer<typeof checkoutJobSchema>>({
    resolver: zodResolver(checkoutJobSchema),
    shouldUseNativeValidation: true,
    defaultValues: {
      ...defaultCheckoutData
    }
  })

  const _getEmployeeIfGcashAffiliate = async () => {
    return await getEmployeeName({
      id: auth.getId(),
    }).then((data: { data: { gcash_affiliate: boolean } }) => {
      form.setValue("gcash_affiliate", data.data.gcash_affiliate)
    })
  }

  const _checkoutJob = async () => {
    setCheckoutJobDataIsLoading(true)
    await checkoutJobV2({
      id: parseInt(job_id),
    }).then(({ data }: { data: CheckoutDataFetched }) => {
      if (data) {
        setCheckoutJobDataFetched(data)
      }
    }).finally(() => setCheckoutJobDataIsLoading(false))
  }

  useEffect(() => {
    _checkoutJob()
    _getEmployeeIfGcashAffiliate()
  }, [])

  useEffect(() => {
    if (prev === '') {
      setPrev(location.state.prev);
    }
  }, [prev, location]);

  useEffect(() => {
    if (commonJob && !!checkoutJobDataFetched) {
      const data = checkoutJobDataFetched;
      const { isNewJob, referral_count, referral_fee, old_referral_count, old_referral_fee, hiring_bonus, blue_collar } = commonJob
      if (isNewJob) {
        setCheckoutData({
          job_title: data.title,
          company_image: data.company.company_image,
          company_name: data.company.name,
          email: data.user.email,
        })
        form.setValue("referral_fee", referral_fee)
        form.setValue("referral_count", referral_count)
        form.setValue("hiring_bonus", hiring_bonus)
        form.setValue("blue_collar", blue_collar)
        form.setValue("id", parseInt(job_id))
      } else {
        //for update data
        setCheckoutData({
          job_title: data.title,
          company_image: data.company.company_image,
          company_name: data.company.name,
          email: data.user.email,
        })
      }
    }

  }, [checkoutJobDataFetched, commonJob]);


  useEffect(() => {
    console.log(commonJob, "first common_job")
    !commonJob.id && history.push(`/create-job/3/0/${job_id}`);
  }, [step, sub, job_id])


  //active page base on step and sub
  const ActivePage = () => pages[step][sub].element

  //active heading
  const ActiveHeading = () => (
    <div className="flex p-4">
      <div className="flex-auto text-2xl font-semibold text-pj-blue-300">
        {pages[step][sub].title}
      </div>
      <div
        className={`w-10 transparent flex items-start justify-center ${Object.keys(pages[step]).length > 1 ? 'items-start' : 'items-center'
          }`}
      >
        <Link to={prev}>
          <AiOutlineClose className="text-xl text-pj-blue-100" />
        </Link>
      </div>
    </div>
  )

  return (

    <motion.div
      variants={slideIn}
      className="fixed flex items-center justify-center w-full h-screen bg-pj-blue-500"
    >
      <div className='flex flex-col w-4/5 h-full px-2 md:w-4/5 lg:w-3/5 xl:w-2/5'>
        <JobSteps step={step} />
        <motion.div
          variants={slideIn}
          animate="animate"
          initial="initial"
          className="relative flex flex-col w-full h-full overflow-hidden bg-white rounded-t-lg">

          <ActiveHeading /> {
            checkoutJobDataIsLoading &&
            <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black opacity-10' >
              <CircularProgress style={{ color: "white" }} size={50} />
            </div>
          }
          <FormProvider {...form}>
            <ActivePage />
          </FormProvider>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CheckoutV2;
