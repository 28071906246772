import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/buttons/button';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import moment from 'moment';
import { getLatestTransaction } from '../../../utilities/api_v2/checkout';
import { useCheckoutStore } from '../lib/store/checkout';
import { useFormContext } from 'react-hook-form';
import { checkoutJobSchema } from '../lib/schema';
import { z } from 'zod';
import { computer } from '../lib/services';

const StepFourThree = () => {
  const form = useFormContext<z.infer<typeof checkoutJobSchema>>()
  const { checkoutData, currentTransactionId } = useCheckoutStore()
  const location = useHistory();
  const { overAllTotal } = computer(form.getValues())

  return (
    <div className="px-4">
      <div className="mb-10 text-2xl text-center text-pj-blue-400">
        {lang.step_four.payment_complete}
      </div>
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-lg text-pj-green-300">
          {lang.step_four.new_job}
        </div>
        <div className="w-1/2 text-lg text-right text-pj-blue-400">
          {lang.formatString(
            lang.step_four.amount,
            overAllTotal.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className="w-full p-4 mt-4 border-2 border-gray-200 rounded-lg">
        <div
          className={`w-32 h-16 bg-cover bg-left flex justify-center items-center text-gray-500 ${(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
            }`}
          style={{ backgroundImage: `url(${checkoutData.company_image})` }}
        >
          {(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            abbreviate(checkoutData.company_name ?? '')}
        </div>
        <div className="mt-2 text-lg text-pj-blue-400">{`${checkoutData.job_title} • ${checkoutData.company_name}`}</div>
      </div>
      <div className="py-2 mt-4">
        <div className="flex justify-between pb-2">
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            Transaction ID
          </div>
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            {checkoutData?.transaction_id}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            Date of Transaction
          </div>
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            {checkoutData?.created_at
              ? moment(checkoutData?.created_at).format('MMMM Do YYYY, h:mm a')
              : '---'}
          </div>
        </div>
      </div>
      <div className="px-20 mt-16 mb-10 text-lg text-center text-pj-blue-100">
        {lang.formatString(lang.step_four.activated, checkoutData.email)}
      </div>
      <div className="flex flex-row content-center justify-center mt-32">
        <div className="mx-1">
          <Button
            to={{
              pathname: `/transactions/${currentTransactionId}`,
              state: { prev: location.location.pathname },
            }}
            label={lang.step_four.view_transaction}
            light
          />
        </div>
        <div className="mx-1">
          <Button
            to={{
              pathname: `/job-posts/pending`,
              state: { prev: location.location.pathname },
            }}
            label={lang.step_four.start_hiring}
          />
        </div>
      </div>
    </div>
  );
};

export default StepFourThree;
