import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useEffect, useState } from 'react';

const StepTwoTwenty = () => {
  const { onNext } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const { setValue, getValues, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const [isVisible, setIsVisible] = useState(false)

  const { visible } = getValues()

  const _privacyHandler = (value: boolean) => {
    setIsVisible(value)
    setValue("visible", value)
  }

  useEffect(() => {
    setIsVisible(visible)
  }, [visible])

  const _onNext = async () => {
    const isValid = await trigger("visible")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <div>
        <label
          className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${isVisible && 'bg-pj-green-100'
            }`}
          onClick={() => _privacyHandler(true)}
        >
          <div className="flex items-start justify-start w-10">
            <input
              type="radio"
              checked={isVisible}
              onChange={() => { }}
            />
          </div>
          <div style={{ marginTop: -6 }}>
            <p className="p-0 m-0 text-lg text-pj-blue-500">
              Public{' '}
              <span className="text-sm">
                (I want it to appear on the job list)
              </span>
            </p>
          </div>
        </label>
      </div>
      <div>
        <label
          className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${!isVisible && 'bg-pj-green-100'
            }`}
          onClick={() => _privacyHandler(false)}
        >
          <div className="flex items-start justify-start w-10">
            <input
              type="radio"
              checked={!isVisible}
              onChange={() => { }}
            />
          </div>
          <div style={{ marginTop: -6 }}>
            <p className="p-0 m-0 text-lg text-pj-blue-500">
              Private{' '}
              <span className="text-sm">
                (I want it to be visible within my company or link recipients)
              </span>
            </p>
          </div>
        </label>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoTwenty;
