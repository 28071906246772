import { motion } from 'framer-motion';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { stagger } from '../../../framer';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';
import { useEffect, useState } from 'react';

const StepTwoZero = () => {
  const { onNext } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const { getValues, setValue, register, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const [isBlueCollar, setIsBlueCollar] = useState<"blue_collar" | "office_based">()


  const _onChange = (value: any) => {
    //turning point
    const { job_position_level } = getValues()
    if (value.target.value === "blue_collar") {
      setIsBlueCollar("blue_collar")
      setValue("referral_fee", 100)
      setValue("hiring_bonus", 100)
      setValue("referral_count", 3)
      setValue("job_position_level", "Entry Level (1 - 4 yrs work experience)")
      setValue('disbursement_type', "full");
    } else {
      setIsBlueCollar("office_based")
      setValue("referral_fee", 500)
      setValue("hiring_bonus", 500)
      setValue("referral_count", 2)
      setValue("job_position_level", job_position_level)
      setValue('disbursement_type', undefined);
    }
  };

  useEffect(() => {
    const { blue_collar, job_position_level } = getValues()
    if (blue_collar === "blue_collar") {
      setIsBlueCollar("blue_collar")
      setValue("referral_fee", 100)
      setValue("hiring_bonus", 100)
      setValue("referral_count", 3)
      setValue("job_position_level", "Entry Level (1 - 4 yrs work experience)")
      setValue('disbursement_type', "full");
    } else {
      setIsBlueCollar("office_based")
      setValue("referral_fee", 500)
      setValue("hiring_bonus", 500)
      setValue("referral_count", 2)
      setValue("job_position_level", job_position_level)
      setValue('disbursement_type', undefined);
    }
  }, [getValues, setValue])

  const _onNext = async () => {
    const isValid = await trigger("blue_collar")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="w-full overflow-x-hidden overflow-y-scroll"
        style={{ height: 'calc(100vh - 25rem)' }}
      >
        <div className="flex flex-col flex-1 overflow-x-hidden">
          <div className="overflow-y">
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${isBlueCollar === "blue_collar" && 'bg-pj-green-100'
                }`}
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  value={"blue_collar"}
                  {...register("blue_collar")}
                  onChange={_onChange}
                  checked={isBlueCollar === "blue_collar"}
                />
              </div>
              <div className="w-full" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{"Blue-collar"}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  Roles requiring manual labor.
                </p>
              </div>
            </label>

            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-b border-gray-200 ${isBlueCollar === "office_based" && 'bg-pj-green-100'
                }`}
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  value={"office_based"}
                  {...register("blue_collar")}
                  onChange={_onChange}
                  checked={isBlueCollar === "office_based"}
                />
              </div>
              <div className="w-full" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{"Office-based"}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  Office-based desk jobs, highly-skilled or specialized jobs.
                </p>
              </div>
            </label>
          </div>
        </div>
      </motion.div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoZero;
