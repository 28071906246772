import { Link, useLocation, useParams } from 'react-router-dom';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { Params } from '..';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';

const StepThreeTwo = () => {
  const { job_id }: Params = useParams()
    const { 
      selectedCompany, 
    } = useCreateJobStore();
  const location: any = useLocation();
  const { getValues } = useFormContext<z.infer<typeof createJobSchema>>();
  const commonJob = getValues();
  
  return (
    <div className="p-4">
      <div className="mb-10 text-2xl font-bold text-center text-pj-blue-500">
        {lang.step_three.job_posted}
      </div>
      <div className="w-full p-4 mt-4 mb-5 border rounded-lg">
        <div
          className={`w-32 h-16 bg-cover bg-left flex justify-center items-center text-gray-500 ${
            (!selectedCompany?.logo || selectedCompany.logo?.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
          }`}
          style={selectedCompany?.logo && { backgroundImage: `url(${selectedCompany?.logo})` }}
        >
          {(!selectedCompany?.logo || selectedCompany.logo?.length <= 0) &&
            abbreviate(selectedCompany?.name ?? '')}
        </div>
        <div className="mt-2 text-lg text-pj-blue-400">{`${commonJob.title} • ${selectedCompany?.name}`}</div>
      </div>
      <div className="flex justify-center ">
        <div className="w-1/2 px-10 text-xl text-center text-pj-blue-100">
          {lang.step_three.active}
        </div>
      </div>

      <div className="flex flex-row justify-center mt-10 space-between">
        <div className="w-auto mx-2">
          <Link to="/job-posts/active">
            <div className="px-4 py-2 text-lg bg-gray-200 border border-gray-300 rounded-full cursor-pointer text-pj-blue-500">
              {lang.step_three.go_to_job}
            </div>
          </Link>
        </div>
        <div className="w-auto mx-2">
          <Link
            to={{
              pathname: `/checkout/4/1/${job_id}`,
              state: {
                fromCreateJob: true,
                prev: location?.pathname,
              },
            }}
          >
            <div className="px-4 py-2 text-lg text-white rounded-full cursor-pointer bg-pj-green-300">
              {lang.step_three.proceed_checkout}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StepThreeTwo;
