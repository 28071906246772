import { motion } from 'framer-motion';
import { useFormContext } from 'react-hook-form';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { createJobSchema } from '../lib/schema';
import { z } from 'zod';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';

const StepTwoTwelve = () => {
  const { onNext } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const { control, formState: {errors}, trigger } = useFormContext<z.infer<typeof createJobSchema>>();

  const _onNext = async () => {
    const isValid = await trigger("description")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
    >
      <div className="flex-initial h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <InputField.Textarea
            label={lang.step_two.job_description}
            name="description"
            control={control}
            rules={{ required: true }}
            error={errors?.description}
            placeholder={lang.step_two.describe_job_placeholder}
            rows={10}
          />
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoTwelve;
