import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { stagger } from '../../../framer';
import { useFormContext } from 'react-hook-form';
import { createJobSchema } from '../lib/schema';
import { z } from 'zod';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';

const blue_collar_fees = [{
  label: "PHP 100",
  value: 100
}, {
  label: "PHP 200",
  value: 200
}, {
  label: "PHP 300",
  value: 300
}, {
  label: "PHP 400",
  value: 400
}, {
  label: "PHP 500",
  value: 500
}]

const white_collar_fees = [{
  label: "PHP 500",
  value: 500
}, {
  label: "PHP 1,000",
  value: 1000
}, {
  label: "PHP 1,500",
  value: 1500
}, {
  label: "PHP 2,000",
  value: 2000
}, {
  label: "PHP 2,500",
  value: 2500
}]


const StepTwoTenFirst = () => {
  const {
    withApplicants,
    onNext
  } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const { trigger, getValues, setValue } = useFormContext<z.infer<typeof createJobSchema>>()
  const [select, setSelect] = useState<number | null>(null);

  const { blue_collar, hiring_bonus } = getValues()

  const hiring_bonuss = blue_collar === "blue_collar" ? blue_collar_fees : blue_collar === "office_based" ? white_collar_fees : []

  useEffect(() => {
    if (!!hiring_bonus) {
      setValue("hiring_bonus", hiring_bonus)
      setSelect(hiring_bonus)
    }
  }, [setValue, hiring_bonus])

  useEffect(() => {
    if (!hiring_bonus) {
      if (blue_collar === "blue_collar") {
        setValue("hiring_bonus", 100)
        setSelect(100)
      } else {
        setValue("hiring_bonus", 500)
        setSelect(500)
      }
    }
  }, [blue_collar, setValue, hiring_bonus])

  const _onNext = async () => {
    const isValid = await trigger("hiring_bonus")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <div className="relative flex flex-col flex-initial p-4 border-b">
        <p className='text-sm -mt-7 text-pj-blue-300'>The Hiring Bonus will be automatically disbursed to the hired candidate immediately after they are tagged as 'Hired'</p>
        <p className="p-0 m-0 mt-4 text-base text-pj-blue-100">
          Choose amount
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
        >
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <div className="overflow-y">
              {
                hiring_bonuss.map((fee) => {
                  return <label
                    key={fee.value}
                    className={`flex flex-row w-full p-4 pl-8 cursor-pointer border-b border-gray-200 ${select === fee.value && 'bg-pj-green-100'
                      }`}
                    onChange={(e) => {
                      if (!withApplicants) {
                        setSelect(fee.value)
                        setValue("hiring_bonus", fee.value)
                      }
                    }}
                  >
                    <div className="flex items-start justify-start w-10">
                      <input
                        type="radio"
                        className='h-full'
                        checked={select === fee.value}
                        onChange={() => { }}
                      />
                    </div>
                    <div className="w-full">
                      <p className="p-0 m-0 text-pj-blue-500">{fee.label}</p>
                    </div>
                  </label>
                })
              }
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoTenFirst;
