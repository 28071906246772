import { motion } from 'framer-motion';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { Address } from '../../../types/job-create';
import NewAddress from '../components/new-address';
import { useFormContext } from 'react-hook-form';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { createJobSchema } from '../lib/schema';
import { z } from 'zod';
import { Params } from '..';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';

const StepTwoFive = () => {
  const { onNext, setNewAddresses, newAddresses, selectedCompany, activeAddress, setActiveAddress } = useCreateJobStore()
  const { setValue, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()
  const [show, setShow] = useState(false as boolean);

  const _onChange = (value: string, data: Address) => {
    setActiveAddress(value)
    console.log({
      ...data
    })
    setValue("address", data.address)
    setValue("city_id", data.city_id)
    setValue("city_name", data.city_name)
    setValue("home_based", data.home_based)
    setValue("province_id", data.province_id)
  };

  const _insertAddress = (data: Address) => {
    setNewAddresses(prev => [...prev, data])
  }

  const _onNext = async () => {
    const isValid = await trigger("address")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }


  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="w-full h-full overflow-x-hidden overflow-y-scroll"
      // style={{ height: 'calc(100vh - 25rem)' }}
      >
        <div className="flex flex-col flex-1 overflow-x-hidden">
          <div className="overflow-y">
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${activeAddress === 'company_address' && 'bg-pj-green-100'
                }`}
              onClick={() =>
                _onChange('company_address', {
                  address: selectedCompany?.address ?? '',
                  city_id: selectedCompany?.city_id ?? 0,
                  province_id: selectedCompany?.province_id ?? 0,
                  home_based: false,
                  city_name: selectedCompany?.city_name,
                })
              }
            >
              <div className="flex items-start justify-start w-10 h-full">
                <input
                  type="radio"
                  // {...register("address")}
                  value="company_address"
                  checked={activeAddress === 'company_address'}
                />
              </div>
              <div className="w-64" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{selectedCompany?.city_name}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  {selectedCompany?.address}
                </p>
              </div>
            </label>
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${activeAddress === 'home_based' && 'bg-pj-green-100'
                }`}
              onClick={() =>
                _onChange('home_based', {
                  address: selectedCompany?.address,
                  city_id: selectedCompany?.city_id,
                  province_id: selectedCompany?.province_id,
                  home_based: true,
                })
              }
            >
              <div className="flex items-start justify-start w-10 h-full">
                <input
                  type="radio"
                  // {...register("address")}
                  value="home_based"
                  checked={activeAddress === 'home_based'}
                />
              </div>
              <div className="w-64" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">
                  {lang.step_two.home_base}
                </p>
              </div>
            </label>
            {newAddresses?.map((address: Address, index: number) => {
              return (
                <label
                  className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${activeAddress === `${index}` && 'bg-pj-green-100'
                    }`}
                  onClick={() =>{
                    _onChange(`${index}`, {
                      address: address.address,
                      city_id: address.city_id,
                      province_id: address.province_id,
                      home_based: false,
                      city_name: address?.city_name,
                    })
                  }
                  }
                >
                  <div className="flex items-start justify-start w-10 h-full">
                    <input
                      type="radio"
                      // {...register("address")}
                      value={`${index}`}
                      checked={activeAddress === `${index}`}
                    />
                  </div>
                  <div className="w-64" style={{ marginTop: -6 }}>
                    <p className="p-0 m-0 text-pj-blue-500 ">
                      {address?.city_name}
                    </p>
                    <p className="p-0 m-0 text-sm text-pj-blue-100">
                      {address.address}
                    </p>
                  </div>
                </label>
              );
            })}
          </div>
          <div className="p-4">
            <div
              className={`w-full p-2 px-3 bg-gray-100 border cursor-pointer ${show ? 'rounded-md' : 'rounded-full'
                }`}
              onClick={() => setShow(true)}
            >
              <div className="flex flex-row justify-between">
                <div className="flex items-center text-sm text-pj-blue-100">
                  Add another location
                </div>
                <div className="flex items-center text-lg text-pj-blue-100">
                  {show ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                </div>
              </div>
              {show && <NewAddress callback={_insertAddress} />}
            </div>
          </div>
        </div>
      </motion.div>
      {/* <Loading show={true} /> */}
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} disabled={!activeAddress} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoFive;
