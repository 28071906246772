import { create } from "zustand";
import { CheckoutData, CheckoutDataFetched, PaymentType, Steps } from "../../../../types/checkout";
import StepFourOne from "../../steps/step-four-one";
import StepFourTwo from "../../steps/step-four-two";
import StepFourThree from "../../steps/step-four-three";
import { insertTransaction } from "../../../../utilities/api_v2/checkout";
import { CommonJob } from "../../../job-create-v2/lib/store.ts/job-create";
import { defaultCommonJob } from "../../../job-create-v2/lib/store.ts/const";
import StepFourZero from "../../steps/step-four-zero";

type State = {
  checkoutData: CheckoutData;
  checkoutJobDataIsLoading: boolean;
  payment_type: PaymentType;
  postingAmount: number;
  checkoutJobDataFetched?: CheckoutDataFetched;
  currentTransactionId: number;
  commonJob: CommonJob;
}

type Actions = {
  setCheckoutData: (data: CheckoutData) => void;
  setCheckoutJobDataIsLoading: (isLoading: boolean) => void;
  setPaymentType: (type: PaymentType) => void;
  setPostingAmount: (amount: number) => void;
  setCheckoutJobDataFetched: (data: CheckoutDataFetched) => void;
  setCurrentTransactionId: (id: number) => void;
  setCommonJob: (updater: (prev: CommonJob) => CommonJob) => void;
}

type Functions = {
  pages: Steps;
  _checkout: (commonJob: CommonJob) => Promise<string | null>
  _insertTransaction: (data: any) => Promise<any>
}
const pages = {
  4: {
    0: {
      element: <StepFourZero />,
      title: 'Order Summary'
    },
    1: {
      element: <StepFourOne />,
      title: 'Checkout'
    },
    2: {
      element: <StepFourTwo />,
      title: 'Checkout'
    },
    3: {
      element: <StepFourThree />,
      title: ""
    }
  },
}

export const useCheckoutStore = create<State & Actions & Functions>((set, get) => ({
  //common job data from form
  commonJob: defaultCommonJob,
  setCommonJob: (updater) => set((state) => ({
    commonJob: updater(state.commonJob)
  })),
  checkoutData: {
    job_title: "",
    company_image: "",
    company_name: "",
    email: "",
  },
  setCheckoutData: (data: CheckoutData) => set({ checkoutData: data }),

  checkoutJobDataIsLoading: false,
  setCheckoutJobDataIsLoading: (isLoading: boolean) => set({ checkoutJobDataIsLoading: isLoading }),

  postingAmount: 0,
  setPostingAmount: (amount: number) => set({ postingAmount: amount }),

  payment_type: "bank_transfer",
  setPaymentType: (type: PaymentType) => set({ payment_type: type }),

  checkoutJobDataFetched: undefined,
  setCheckoutJobDataFetched: (data: CheckoutDataFetched) => set({ checkoutJobDataFetched: data }),

  currentTransactionId: 0,
  setCurrentTransactionId: (amount: number) => set({ currentTransactionId: amount }),

  //functions for checkout page
  pages,
  _checkout: async (commonJob: CommonJob) => {
    // const { setCheckoutJobDataIsLoading, checkoutData, _insertTransaction, params : { job_id }, payment_type } = get()
    // setCheckoutJobDataIsLoading(true)
    // if (commonJob) {
    //   const updateData = Object.fromEntries(
    //     Object.entries(commonJob)
    //       .filter(([key]) => {
    //         return !(key === "old_referral_count_2" || key === "old_referral_fee_2" || key === "isNewJob")
    //       })
    //   );
    //   await updateJobV2({
    //     ...updateData,
    //   }).then( async () => {
    //     await _insertTransaction({
    //       job_id: job_id,
    //       total_vat: checkoutData.vat,
    //       total_commission: checkoutData.processing_fee,
    //       amount: checkoutData.total,
    //       transaction_id: `${moment().unix()}`,
    //       payment_type,
    //       // posting_fee: postingFeeChecker ? 0 : postingFee,
    //       posting_fee: checkoutData.posting_fee,
    //       employer_id: auth.getId(),
    //     })
    //   })
    //   .catch((e)=>{
    //     console.log(e)
    //     return null
    //   })
    //   .finally(()=> setCheckoutJobDataIsLoading(false))
    // }
    // return `/checkout/4/3/${job_id}`
    return ''
  },
  _insertTransaction: async (data: any) => {
    const { setCurrentTransactionId } = get()

    await insertTransaction(data).then((dt: any) => {
      const res = dt.data
      setCurrentTransactionId(res.id);
    }).catch(e => {
      console.log(e)
    })
  }
}))