import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { SignupContext } from '../../utilities/context';
import { SignupInitialState } from '../../utilities/states';
import { OnClickFunction, Signup } from '../../utilities/types';
import LoginView from './view';
import { register } from '../../utilities/api_v2/authentication';

const SignupController = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm<any>({
    defaultValues: {
      ...SignupInitialState.data,
    },
  });
  const [showModal, setShowModal] = useState<boolean>(false);

  const _submitForm: OnClickFunction = async data => {
    if (data) {
      const result: any = await register(data);
      console.log(result, 'test')
      if(result?.data?.errors === 'Invalid Code!'){
        setError('code', {
          type: 'focus',
          message: result.data.errors,
        });
      }
      else if (result?.data?.errors) {
        setError('email', {
              type: 'focus',
              message: result.data.errors,
            });
      } else {
        setShowModal(true);
      }
    }
  };

  const clearError = () => {
    clearErrors && clearErrors();
  };

  const value: Signup = {
    ...SignupInitialState,
    errors,
    control,
    showModal,
    handleSubmit,
    _submitForm,
    watch,
    setError,
    clearErrors,
    clearError,
  };
  return (
    <SignupContext.Provider value={value}>
      <LoginView />
    </SignupContext.Provider>
  );
};

export default SignupController;
