import { z } from "zod";

export const checkoutJobSchema = z.object({
    referral_fee: z.number(),
    referral_count: z.number(),
    old_referral_fee: z.number(),
    old_referral_count: z.number(),
    processing_fee: z.number(),
    posting_fee: z.number(),
    total: z.number(),
    gcash_affiliate : z.boolean(),
    vat: z.number(),
    id: z.number(),
    hiring_bonus: z.number(),
    agreed : z.boolean(),
    postingAmount: z.number(),
    payment_type: z.enum(['debit_card', 'e_wallets', 'qr_ph','bank_transfer', 'send_bill']),
    blue_collar: z.enum(['blue_collar', 'office_based']),
})

export const eWalletSchema = z.object({
    e_wallet : z.enum(['gcash'])
})

export type CheckoutJobType = z.infer<typeof checkoutJobSchema>