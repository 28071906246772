const CeoModal = ({dialog, setDialog }:any) => {

  
  const handleOnClick = () => {
    setDialog(false)
  }
  return (
    <div>
      { dialog && (
        <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"></div>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
              <div className="relative overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg">
                <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    {/* <div className="flex items-center justify-center flex-shrink-0 w-12 h-12 mx-auto bg-red-100 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <svg className="w-6 h-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                      </svg>
                    </div> */}
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">Referral Fee for Executive Level Roles</h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please contact our sales team at info@pasajob.com to set the appropriate referral fee amount for CEO / VP / Director roles.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-50 sm:flex sm:flex-row-reverse sm:px-6">
                  <button 
                    onClick={handleOnClick}
                    type="button" 
                    className="inline-flex justify-center w-full py-2 mt-3 text-sm font-semibold text-white uppercase rounded-full shadow-sm bg-pj-green-200 px-7 ring-1 ring-inset ring-gray-300 hover:bg-green-300 sm:mt-0 sm:w-auto"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
  
    </div>
  )
}

export default CeoModal