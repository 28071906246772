import { motion } from 'framer-motion';
import { useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { OnClickFunction } from '../../../utilities/types';
import { useFormContext } from 'react-hook-form';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { Params } from '..';
import { useHistory, useParams } from 'react-router-dom';

const StepTwoEleven = () => {
  const { fetchedDatas: { qualifications }, onNext } = useCreateJobStore()
  const { control, formState: { errors }, setValue, getValues, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()

  const { minimum_qualification, blue_collar } = getValues()

  const [select, setSelect] = useState<string | undefined>(
    minimum_qualification
  );

  let filteredQualifications = []

  const qualificationToRemoveIfBlueCollar = ['Master’s Degree / Unit', 'Professional License (Board/Bar)', 'Doctorate']

  if (blue_collar === "blue_collar") {
    filteredQualifications = qualifications.filter((qual) => !qualificationToRemoveIfBlueCollar.includes(qual.value))
  } else {
    filteredQualifications = qualifications
  }

  const _onClick: OnClickFunction = (qualification: string) => {
    setSelect(qualification);
    setValue && setValue('minimum_qualification', qualification);
  };

  const _onNext = async () => {
    const isValid = await trigger("minimum_qualification")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <InputField
        name="minimum_qualification"
        label=""
        control={control}
        error={errors?.minimum_qualification}
        type="hidden"
        defaultValue={select}
        rules={{ required: true }}
      />
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_qualification}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
          id="journal-scroll"
        >
          <div
            className="flex flex-col flex-1 overflow-x-hidden"
            id="journal-scroll"
          >
            <div className="overflow-y">
              {filteredQualifications.map(({ label, value }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 text-lg flex flex-initial cursor-pointer ${select === value && 'bg-pj-green-100'
                      }`}
                    onClick={() => _onClick(value)}
                    key={`${index}`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} disabled={!select} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoEleven;
