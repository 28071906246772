import { create } from 'zustand'
import { CompanyType, FetchedDatas, Steps, CreateJobParams, Address, JobType, IsCheckoutCheckerDatas } from '../../../../types/job-create'
import StepOne from '../../steps/step-one-one';
import StepTwo from '../../steps/step-two-one';
import StepTwoTwo from '../../steps/step-two-two';
import StepTwoThree from '../../steps/step-two-three';
import StepTwoZero from '../../steps/step-two-zero';
import StepTwoFour from '../../steps/step-two-four';
import StepTwoFive from '../../steps/step-two-five';
import StepTwoSix from '../../steps/step-two-six';
import StepTwoSeven from '../../steps/step-two-seven';
import StepTwoEight from '../../steps/step-two-eight';
import { auth } from '../../../../utilities/auth';
import StepTwoNine from '../../steps/step-two-nine';
import StepTwoTen from '../../steps/step-two-ten';
import StepTwoEleven from '../../steps/step-two-eleven';
import StepTwoTwentyOne from '../../steps/step-two-twenty-one';
import StepTwoTwelve from '../../steps/step-two-twelve';
import StepTwoNineteen from '../../steps/step-two-nineteen';
import StepTwoTwenty from '../../steps/step-two-twenty';
import StepThreeOne from '../../steps/step-three-one';
import StepThreeTwo from '../../steps/step-three-two';
import { createJob, createJobV2, updateJobV2 } from '../../../../utilities/api_v2/job_create';
import { updateJob } from '../../../../utilities/api_v2/checkout';
import { z } from 'zod';
import { createJobSchema } from '../schema';
import { Params } from '../..';
import StepTwoTenFirst from '../../steps/step-two-ten-first';
import { UseFormSetValue } from 'react-hook-form';

export type CommonJob = z.infer<typeof createJobSchema>

const defaultFetchedDatas = {
  companies: [],
  industries: [],
  categories: [],
  positions: [],
  employment_types: [],
  experiences: [],
  qualifications: []
}

//pages to hide if blue_collar
export const pageIndexToHide = [4, 9, 10]

type State = {
  isEdit: boolean;
  activeAddress?: string;
  fetchedDatas: FetchedDatas;
  fetchedDatasIsLoading: boolean;
  selectedCompany: CompanyType | null;
  newAddresses: Address[]
  withApplicants: boolean;
  confirmJobPostData: boolean;
}

type Actions = {
  setConfirmJobPostData: (isEdit: boolean) => void;
  setIsEdit: (isEdit: boolean) => void;
  setActiveAddress: (active: string) => void;
  setNewAddresses: (updater: (prev: Address[]) => Address[]) => void;
  setFetchedDatas: (updater: (prev: FetchedDatas) => FetchedDatas) => void;
  setFetchedDatasIsLoading: (isLoading: boolean) => void;
  setSelectedCompany: (company: CompanyType) => void;
  setWithApplcants: (withApplicants: boolean) => void;
}

type Functions = {
  onNext: (params: Params) => string | null;
  _createJobPost: () => Promise<any>;
  _updateJob: (data: CommonJob) => Promise<any>;
  totalSubStep: (step: string) => number;
  pages: () => Steps | null;
  resetCreateJobStore: () => void;
}

const pages = {
  1: {
    0: {
      element: <StepOne />,
      title: "Select the company you want to post a job for."
    }
  },
  2: {
    0: {
      element: <StepTwoZero />,
      title: "What type of job are you posting?"
    },
    1: {
      element: <StepTwo />,
      title: "What are you looking for?"
    },
    2: {
      element: <StepTwoTwo />,
      title: "What industry does the job fall under?"
    },
    3: {
      element: <StepTwoThree />,
      title: "What specialization does your job post fall under?"
    },
    4: {
      element: <StepTwoFour />,
      title: "What position level?"
    },
    5: {
      element: <StepTwoFive />,
      title: "Select a work location."
    },
    6: {
      element: <StepTwoSix />,
      title: "What employment type is required for this post?"
    },
    7: {
      element: <StepTwoSeven />,
      title: "What skills are you looking for?"
    },
    8: {
      element: <StepTwoEight />,
      title: "How much salary are you offering for this job?"
    },
    9: {
      element: <StepTwoNine />,
      title: "How much is the referral fee for this job?"
    },
    10: {
      element: <StepTwoTenFirst />,
      title: 'How much is the Hiring Bonus for this job?'
    },
    11: {
      element: <StepTwoTen />,
      title: 'What level of experience does your job require?'
    },
    12: {
      element: <StepTwoEleven />,
      title: "What is your job's minimum qualifications?"
    },
    13: {
      element: <StepTwoTwelve />,
      title: "Tell applicants more about your job post."
    },
    14: {
      element: <StepTwoNineteen />,
      title: 'How many individuals would you like to hire for this role?'
    },
    15: {
      element: <StepTwoTwenty />,
      title: 'What is the privacy of this job post?'
    }
  },
  3: {
    0: {
      title: '',
      element: <StepThreeOne />,
    },
    1: {
      title: '',
      element: <StepThreeTwo />,
    }
  }
}


export const useCreateJobStore = create<State & Actions & Functions>((set, get) => ({

  isEdit: false,
  setIsEdit: (isEdit: boolean) => set({ isEdit }),

  confirmJobPostData: false,
  setConfirmJobPostData: (confirmJobPostData: boolean) => set({ confirmJobPostData }),

  //fetched datas from api
  fetchedDatas: defaultFetchedDatas,
  setFetchedDatas: (updater) => set((state) => ({
    fetchedDatas: updater(state.fetchedDatas)
  })),

  fetchedDatasIsLoading: false,
  setFetchedDatasIsLoading: (isLoading: boolean) => set({ fetchedDatasIsLoading: isLoading }),

  //data for selected company in step one one 
  selectedCompany: null,
  setSelectedCompany: (company: CompanyType | null) => {
    if (company) {
      set({ selectedCompany: company })
    } else {
      set({ selectedCompany: null })
    }
  },

  //pages filtered if is blue_collar
  pages: () => {
    return pages as Steps
  },

  //total steps used for counting substeps to display the progress of create job
  totalSubStep: (step) => {
    const stepArray = Object.values(get().pages()[step]);
    return stepArray.length
  },

  //added addresses
  newAddresses: [],
  setNewAddresses: (updater) => set((state) => ({
    newAddresses: updater(state.newAddresses)
  })),

  isCheckoutCheckerDatas: {
    referral_count: undefined,
    referral_fee: undefined
  },

  //for setting active radio button address
  activeAddress: 'company_address',
  setActiveAddress: (active: string) => set({ activeAddress: active }),

  withApplicants: false,
  setWithApplcants: (withApplicants: boolean) => set({ withApplicants }),

  /* Functions for create and update job */

  //on click next button handler
  onNext: ({ step, sub, job_id, is_duplicate }: { step: string; sub: string; job_id?: string; is_duplicate?:string }) => {
    const isEdit = get().isEdit
    const subStepCount = get().totalSubStep(step) - 1
    if (isEdit && sub !== "2") {
      return `/create-job/3/0/${job_id ? `${job_id}${is_duplicate? '/duplicate' : ''}`:''}`
    } else if (Number(sub) >= subStepCount) {
      return `/create-job/${Number(step) + 1}/0/${job_id ? `${job_id}${is_duplicate? '/duplicate' : ''}`:''}`
    } else {
      return `/create-job/${step}/${Number(sub) + 1}/${job_id ? `${job_id}${is_duplicate? '/duplicate' : ''}`:''}`
    }
  },

  //on create job function
  _createJobPost: async () => {
  },

  _updateJob: async (data: CommonJob) => {
  },

  //trigger to reset create job zustand store
  resetCreateJobStore: () => {
    const {
      setFetchedDatas,
      setFetchedDatasIsLoading,
      setSelectedCompany,
    } = get()
    setFetchedDatas(() => defaultFetchedDatas)
    setFetchedDatasIsLoading(false)
    setSelectedCompany(null)
  }
}))