import { motion } from 'framer-motion';
import { useEffect } from 'react';
import {
  useFieldArray,
  useFormContext,
} from 'react-hook-form';
import { AiOutlineMinusCircle } from 'react-icons/ai';
import AddButton from '../../../components/add-button';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';
import { useCreateJobStore } from '../lib/store.ts/job-create';

const StepTwoSeven = () => {
  const { onNext } = useCreateJobStore()
  const { control, formState: { errors }, register, getValues } = useFormContext < z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'common_job_skills',
  });

  const _onRemove = () => {
    remove(fields.length - 1);
  };
  
  const _onNext = async () => {
    const route = onNext(params)
    history.push(route)
  }

  useEffect(() => {
    if (fields.length <= 0) {
      append({ name: '' });
    }
  }, [append]);

  return (
    <div
      className="flex flex-col flex-1 h-full"
    >
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <div className="flex flex-col flex-1">
            <div className="overflow-y">
              {fields.map((field, index) => {
                let reg = {} as any;
                let defaultValue = null;
                if (register !== undefined && getValues !== undefined) {
                  reg = { ...register(`common_job_skills.${index}.value`) } as any;
                  if (reg.name) {
                    defaultValue = getValues(reg?.name);
                  }
                }
                if (index === fields.length - 1 && index !== 0) {
                  return (
                    <div className="flex flex-row" key={index}>
                      <div className="flex-auto">
                        <InputField
                          label={lang.step_two.enter_a_skill}
                          defaultValue={defaultValue}
                          name={reg.name}
                          control={control}
                          error={errors?.[reg.name]}
                          type="text"
                          rules={{ required: true }}
                        />
                      </div>
                      <div
                        className="flex items-center justify-center w-12 text-red-400 cursor-pointer"
                        onClick={() => _onRemove()}
                      >
                        <AiOutlineMinusCircle />
                      </div>
                    </div>
                  );
                }
                return (
                  <InputField
                    label={lang.step_two.enter_a_skill}
                    defaultValue={defaultValue}
                    name={reg.name}
                    control={control}
                    error={errors?.[reg.name]}
                    type="text"
                    rules={{ required: true }}
                  />
                );
              })}
            </div>
          </div>
          <AddButton
            label={lang.step_two.add_skill}
            onClick={() => append({ name: '' })}
          />
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
          <ButtonNext onClick={async () => await _onNext()}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoSeven;
