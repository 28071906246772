import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { OnClickFunction } from '../../../utilities/types';
import { useFormContext } from 'react-hook-form';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { Params } from '..';
import { useHistory, useParams } from 'react-router-dom';

const StepTwoSix = () => {
  const { fetchedDatas: { employment_types }, onNext } = useCreateJobStore()
  const { setValue, getValues, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()
  const [select, setSelect] = useState<string | null>(null);

  const _onClick: OnClickFunction = (type: string) => {
    setSelect(type);
    setValue && setValue('job_employment_type', type);
  };


  useEffect(() => {
    const job_employment_type = getValues("job_employment_type")
    if (job_employment_type) {
      setSelect(job_employment_type)
      setValue("job_employment_type", job_employment_type)
    }
  }, [getValues, setValue]);

  const _onNext = async () => {
    const isValid = await trigger("job_employment_type")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
      id="journal-scroll"
    >
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_employment_type}
        </p>
      </div>
      <div className="flex-initial overflow-auto h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
          id="journal-scroll"
        >
          <div
            className="flex flex-col flex-1 overflow-x-hidden"
            id="journal-scroll"
          >
            <div className="overflow-y">
              {employment_types.filter((et)=>et.label !== "Employment").map(({ label, value }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 flex flex-initial cursor-pointer ${select === value && 'bg-pj-green-100'
                      }`}
                    onClick={() => _onClick(value)}
                    key={`${index}`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} disabled={!select} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoSix;
