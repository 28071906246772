import React from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { lang } from '../../../../lang';
import { useCreateJobStore } from '../../lib/store.ts/job-create';

type Props = {
  children?: React.ReactNode;
  bold?: boolean;
  title: string;
  url: string;
  jobId?: number;
  hidden?: boolean
};

const EditStep = ({ children, bold, title, url, jobId = null, hidden = false }: Props) => {
  const { setIsEdit } = useCreateJobStore()

  const EditComponent = () => (<Link
    to={{
      pathname: url,
      state: { isEdit: true },
    }}
    className={`${hidden ? 'hidden' : ''}`}
    onClick={() => setIsEdit(true)}
  >
    <div className="flex flex-row w-auto text-gray-400 uppercase">
      <div className="w-auto text-sm">{lang.step_three.edit}</div>
      <div className="w-auto">
        <RiArrowRightSLine
          className="text-xl"
          style={{ marginTop: -1 }}
        />
      </div>
    </div>
  </Link>)

  return (
    <div className="w-full p-4 border-b border-gray-200">
      <div className="flex flex-row w-full">
        <div
          className={`flex-auto text-pj-blue-300 ${bold && 'font-semibold'}`}
        >
          {title}
        </div>
        <EditComponent />
      </div>
      {children}
    </div>
  );
};

EditStep.Content = ({ children }: { children: React.ReactNode }) => {
  return <div className="w-full text-sm text-pj-blue-100">{children}</div>;
};

EditStep.defaultProps = {
  bold: true,
  children: <div></div>,
};

export default EditStep;
