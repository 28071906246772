import { motion } from 'framer-motion';
import { useState } from 'react';
import {
  useFormContext,
} from 'react-hook-form';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { slideIn, stagger } from '../../../framer';
import { lang } from '../../../lang';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';

const StepTwoNineteen = () => {
  const { withApplicants, onNext } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const { formState: { errors }, setValue, getValues, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const { referral_count, blue_collar } = getValues()

  const [referralCount, setReferralCount] = useState(referral_count)

  const min = blue_collar === "blue_collar" ? 3 : 2

  const onIncrement = () => {
    const values = getValues()
    const value = values.referral_count + min
    setReferralCount(value)
    setValue("referral_count", value)
  };

  const onDecrement = () => {
    const values = getValues()
    if (((values.referral_count - min) >= (min))) {
      const value = values.referral_count - min
      setReferralCount(value)
      setValue("referral_count", value)
    }
  };

  const _onNext = async () => {
    const isValid = await trigger("referral_count")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
    >
      <div className="flex-initial h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <div className="flex -mx-3 pj-focus">
            <div className="w-full px-3 mb-5">
              <label
                className={`text-sm focus:border-green-200 text-pj-blue-100`}
              >
                {lang.step_two.head_count}
                <div className="relative flex flex-row w-32 h-10 mt-3 border border-gray-400 rounded-lg">
                  <button
                    type="button"
                    disabled={withApplicants}
                    className="flex w-20 h-full font-semibold text-white border border-gray-400 rounded cursor-pointer bg-pj-blue-100 focus:outline-none"
                    onClick={onDecrement}
                  >
                    <span className="m-auto">-</span>
                  </button>
                  <div className="flex items-center justify-center w-32 text-xs bg-white cursor-default md:text-base">
                    <span>{referralCount}</span>
                  </div>

                  <button
                    type="button"
                    className="flex w-20 h-full font-semibold text-white border border-gray-400 rounded cursor-pointer bg-pj-blue-100 focus:outline-none"
                    onClick={onIncrement}
                  >
                    <span className="m-auto">+</span>
                  </button>
                </div>
              </label>
              {errors.referral_count && (
                <motion.div
                  variants={slideIn}
                  initial="initial"
                  animate="animate"
                  className="pt-1 text-xs text-red-400"
                >
                  {errors.referral_count.message}
                </motion.div>
              )}
            </div>
          </div>
        </motion.div >
      </div >
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div >
  );
};

export default StepTwoNineteen;
