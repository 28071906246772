import { motion } from 'framer-motion';
import { useEffect } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import ButtonExit from '../../../components/buttons/button-exit';
import Company from '../../../components/company';
import Loading from '../components/loader';
import { slideIn, stagger } from '../../../framer';
import { CompanyType } from '../../../types/job-create';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { Params } from '..';

const StepOne = () => {
  const {
    fetchedDatas: { companies },
    fetchedDatasIsLoading,
    selectedCompany,
    setSelectedCompany,
    setIsEdit,
    isEdit
  } = useCreateJobStore();
  const location = useLocation();
  const history = useHistory();
  const { job_id }: Params = useParams()
  const { setValue } = useFormContext<z.infer<typeof createJobSchema>>();

  const _selectedCompany = (company: CompanyType) => {
    if (!job_id) {
      setValue("company_detail_id", company.id)
      setValue("address", company?.address || '')
      setValue("city_id", company?.city_id || 0)
      setValue("province_id", company?.province_id || 0)
      setValue("home_based", false)
      setValue("city_name", company?.city_name)
      setValue("erp_owner", company?.erp_owner)
    } else {
      setValue("company_detail_id", company.id)
    }
    if (isEdit) {
      history.push(`/create-job/3/0/${job_id ?? ""}`);
    } else {
      history.push(`/create-job/2/0/${job_id ?? ""}`);
    }
    setIsEdit(false)
    setSelectedCompany(company);
  }

  useEffect(() => {
    location.search === '?is_admin=true' ? localStorage.setItem('isAdmin', 'true') : localStorage.setItem('isAdmin', 'false');
  }, [location.search])

  return (

    <div className="flex flex-col flex-1" style={{ maxHeight: "90%" }}>
      <div
        className="flex-1 overflow-auto"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full h-full p-4 overflow-x-hidden"
        >
          {companies.map((company, index: number) => (
            <motion.div variants={slideIn} key={index}>
              <Company
                {...company}
                key={`${index}`}
                onClick={_selectedCompany}
                active={selectedCompany?.id === company.id}
              />
            </motion.div>
          ))}
          <Link
            to="/my-companies"
            className="flex items-center justify-between px-4 py-2 mt-5 border rounded-full"
          >
            <span className="text-pj-blue-100">Add a Company</span>
            <BsPlusCircle className="text-lg text-pj-blue-100" />
          </Link>
        </motion.div>
        <Loading show={fetchedDatasIsLoading} />
      </div>
      <div className="flex flex-col flex-1 border-t">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit exit={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepOne;
