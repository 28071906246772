import React from 'react';

const Loader = () => {
  return (
    <div className="flex justify-center">
      <div className='animate-loader'>Matching</div>
      <div className="dot animate-loader"></div>
      <div className="dot animate-loader animation-delay-200"></div>
      <div className="dot animate-loader animation-delay-400"></div>
    </div>
  );
};

export default Loader;
