import { motion } from 'framer-motion';
import { slideIn, stagger } from '../../../framer';
import { lang  } from '../../../lang';

const JobSteps = ({step}:{step:string}) => {
  return (
    <motion.div
      variants={stagger}
      initial="initial"
      animate="animate"
      className="w-full"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="grid grid-cols-4 gap-3 py-2"
      >
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            step==="1" ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            step==="2" ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            step==="3" ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            step==="4" ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
      </motion.div>
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="grid grid-cols-4 gap-2 py-2"
      >
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            step==="1" ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.company}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            step==="2" ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.job_details}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            step==="3" ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.review}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            step==="4" ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.payment}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default JobSteps;
