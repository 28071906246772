import { auth } from "../auth";
import { employer_api_v2 } from "./main";

export const getUserMatchAuthorization = async () => {
  let response = {};

  await employer_api_v2.post('applicant_board/match_authorization', { user_id: auth.getUserData().user.id })
      .then(res => {
          response = res?.data;
      })
      .catch(err => {
          response = err?.response?.data;
      });

      return response
}
 export const getMatchedCandidates = async (data: any) => {
    let response = {};

    await employer_api_v2.post('applicant_board/match_candidates', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
  
        return response
  };

  export const getMatchedCandidateDetails = async (data: any) => {
    let response = {};

    await employer_api_v2.post('applicant_board/match_candidate_details', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
  
        return response
  };