import { auth } from "../../../../utilities/auth";
import { CommonJob } from "./job-create";


export const defaultCommonJob = {
    id: undefined,
    employer_id: auth.getId(),
    blue_collar: "office_based",
    title: undefined,
    industry_id: undefined,
    job_type_id: undefined,
    job_position_level: undefined,
    company_detail_id: undefined,
    address: undefined,
    city_id: undefined,
    province_id: undefined,
    home_based: undefined,
    city_name: undefined,
    job_employment_type: undefined,
    common_job_skills: [],
    referral_fee: undefined,
    minimum_salary: 5000,
    maximum_salary: 20000,
    show_salary: true,
    erp_owner: undefined,
    job_experience: undefined,
    minimum_qualification: undefined,
    disbursement_type: "full",
    description: "",
    referral_count: 2,
    visible: true,
    old_referral_count_2: undefined,
    old_referral_fee_2: undefined,
    isNewJob: undefined,
    transactions: undefined
} as CommonJob