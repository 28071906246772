import { motion } from 'framer-motion';
import { useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { useFormContext } from 'react-hook-form';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useHistory, useParams } from 'react-router-dom';
import { Params } from '..';

type Range = {
  minPrice: number;
  maxPrice: number;
  min: number;
  max: number;
  minThumb: number;
  maxThumb: number;
};

const StepTwoEight = () => {
  const { onNext } = useCreateJobStore()
  const { register, setValue, getValues } = useFormContext<z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()

  const commonJob = getValues()

  const min = 500;
  const max = 200000;
  const minPrice = commonJob.minimum_salary;
  const maxPrice = commonJob.maximum_salary;
  const minThumb = ((minPrice - min) / (max - min)) * 100;
  const maxThumb = 100 - ((maxPrice - min) / (max - min)) * 100;

  const [minmax, setMinMax] = useState<Range>({
    min,
    max,
    minPrice,
    maxPrice,
    minThumb,
    maxThumb,
  });

  const [checked, setChecked] = useState<boolean>(commonJob.show_salary);

  const minTrigger = (value: number) => {
    const minPrice = Math.min(value, minmax.maxPrice - 500);
    const minThumb =
      ((minPrice - minmax.min) / (minmax.max - minmax.min)) * 100;
    setMinMax({
      ...minmax,
      minPrice,
      minThumb,
    });
    setValue && setValue('minimum_salary', minPrice);
  };

  const maxTrigger = (value: number) => {
    const maxPrice = Math.max(value, minmax.minPrice + 500);
    const maxThumb =
      100 - ((maxPrice - minmax.min) / (minmax.max - minmax.min)) * 100;
    setMinMax({
      ...minmax,
      maxPrice,
      maxThumb,
    });
    setValue && setValue('maximum_salary', maxPrice);
  };

  const _onNext = async () => {
    const route = onNext(params)
    history.push(route)
  }

  return (
    <div
      className="flex flex-col flex-1 h-full"
    >
      <div className="flex-initial h-screen/2" id="journal-scroll">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <div className="mb-5">
            <label className="flex items-start justify-start pb-6">
              <div className="flex items-center justify-center flex-shrink-0 w-5 h-5 mr-2 bg-white border-2 border-gray-400 rounded focus-within:border-blue-500">
                <input
                  type="checkbox"
                  className="absolute opacity-0 focus:border-none focus:border-green-200"
                  name="show_salary"
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    setValue && setValue('show_salary', !checked);
                  }}
                />
                <svg
                  className="hidden w-4 h-4 pointer-events-none fill-current text-pj-blue-500"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className="text-sm select-none text-pj-blue-500">
                {lang.step_two.show_salary}
              </div>
            </label>
          </div>
          <div className="flex items-center justify-center">
            <div className="relative w-full">
              <div>
                <input
                  type="range"
                  step="500"
                  className="absolute z-20 w-full h-2 opacity-0 appearance-none cursor-pointer pointer-events-none"
                  min={minmax.min}
                  max={minmax.max}
                  onChange={val => minTrigger(parseInt(val.target.value))}
                  value={minmax.minPrice}
                />

                <input
                  type="range"
                  step="500"
                  className="absolute z-20 w-full h-2 opacity-0 appearance-none cursor-pointer pointer-events-none"
                  min={minmax.min}
                  max={minmax.max}
                  onChange={val => maxTrigger(parseInt(val.target.value))}
                  value={minmax.maxPrice}
                />

                <div className="relative z-10 h-2">
                  <div className="absolute top-0 bottom-0 left-0 right-0 z-10 bg-gray-200 rounded-md"></div>

                  <div
                    className="absolute top-0 bottom-0 z-20 bg-gray-400 rounded-md"
                    style={{
                      right: `${minmax.maxThumb}%`,
                      left: `${minmax.minThumb}%`,
                    }}
                  ></div>

                  <div
                    className="absolute top-0 left-0 z-30 w-6 h-6 -mt-2 -ml-3 bg-white border-2 border-gray-200 rounded-full"
                    style={{
                      left: `${minmax.minThumb}%`,
                    }}
                  ></div>

                  <div
                    className="absolute top-0 right-0 z-30 w-6 h-6 -mt-2 -mr-3 bg-white border-2 border-gray-200 rounded-full"
                    style={{
                      right: `${minmax.maxThumb}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="flex items-center justify-between py-5 space-x-4">
                <div className="w-full">
                  <InputField
                    label=""
                    {...register("minimum_salary")}
                    type="hidden"
                    defaultValue={minmax.minPrice}
                  />
                  <div className="w-full p-4 text-gray-400 bg-gray-200 rounded-lg">
                    {lang.formatString(
                      lang.step_two.php,
                      minmax.minPrice.toLocaleString()
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full p-4 text-gray-400 bg-gray-200 rounded-lg">
                    {lang.formatString(
                      lang.step_two.php,
                      minmax.maxPrice.toLocaleString()
                    )}
                  </div>
                  <InputField
                    label=""
                    {...register("minimum_salary")}
                    type="hidden"
                    defaultValue={minmax.maxPrice}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoEight;
