import { employer_api, employer_api_v2 } from "./main";

export const getJobDetail = async (data: any) => {
  let response = {};

  await employer_api
    .post('getJobDetail', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const createJob = async (data: any) => {
  let response = {};

  await employer_api
    .post('createJob', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getCompanies = async (data: any) => {
  let response = {};

  await employer_api_v2
    .post('job-create/getCompanies', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getRegions = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getRegions')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getQualification = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getQualification')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getTraits = async ({ group }: { group: number }) => {
  let response: any = {};

  await employer_api_v2.post('job-create/getTraits', { group })
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getCategories = async ({ id }: { id: number }) => {
  let response: any = {};

  await employer_api_v2.post('job-create/getCategories', { id })
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getAllCategories = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getAllCategories')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getPositions = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getPositions')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getExperiences = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getExperiences')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getEmploymentTypes = async () => {
  let response: any = {};

  await employer_api_v2.post('job-create/getEmploymentTypes')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}

export const getIndustries = async () => {
  let response = {};

  await employer_api_v2.post('job-create/getIndustries')
    .then(res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
}
export const getJobDetailV2 = async (data: any) => {
  let response = {};

  await employer_api_v2.post('job-create/getJobDetailV2', { ...data })
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};
export const createJobV2 = async (data: any) => {
  let response = {};

  await employer_api_v2.post('job-create/createJobV2', { ...data })
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const updateJobV2 = async (data: any) => {
  let response = {};

  await employer_api_v2.post('job-create/updateJobV2', { ...data })
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};