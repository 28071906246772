import { z } from "zod";

export const createJobSchema = z.object({
    id: z.number().optional(),
    employer_id: z.number(),
    blue_collar: z.enum(["blue_collar", "office_based"]),
    title: z.string().min(2, {message : "Job title must be atleast 2 characters"}),
    industry_id: z.number(),
    job_type_id: z.number(),
    job_position_level: z.string(),
    address: z.string(),
    company_detail_id: z.number(),
    city_id: z.number(),
    province_id: z.number(),
    home_based: z.boolean(),
    city_name: z.string(),
    job_employment_type: z.string(),
    referral_fee: z.number(),
    hiring_bonus: z.number(),
    minimum_salary: z.number(),
    maximum_salary: z.number(),
    show_salary: z.boolean(),
    erp_owner: z.boolean(),
    job_experience: z.string(),
    minimum_qualification: z.string(),
    disbursement_type: z.enum(['full', 'half', 'third', 'forth']),
    description: z.string(),
    referral_count: z.number(),
    visible: z.boolean(),
    common_job_skills: z.array(z.object({
        name : z.string().min(1, {  message : "Please input skill"}),
        value : z.string().min(1, {  message : "Please input skill"}),
        job_id : z.number(),
    })),
    old_referral_count: z.number(),
    old_referral_fee: z.number(),
    isNewJob: z.boolean(),
    transactions : z.array(z.object({
        id:  z.number(), 
        job_id: z.number(),
    }))
})