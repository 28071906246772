import { OnClickFunction } from "../../../../utilities/types"; 

type Props = {
  buttonType?: 'submit' | 'button';
  onClick?: OnClickFunction;
  label?: string;
  disabled: boolean;
  light: boolean;
  full?: boolean;
};

const ButtonNext = ({
  buttonType,
  onClick,
  label = 'Next',
  disabled,
  light,
  full,
}: Props) => {
  return (
    <button
      className={`flex flex-row justify-center px-4 rounded-full text-base h-10 items-center ${disabled
          ? 'text-white bg-pj-green-100'
          : light
            ? 'text-pj-blue-100 cursor-pointer bg-gray-100 border border-gray-200'
            : 'text-white cursor-pointer bg-pj-green-200'
        } ${full && 'w-full'}`}
      type={disabled ? 'button' : buttonType}
      onClick={disabled ? () => { } : onClick}
    >
      {label}
    </button>
  );
};

ButtonNext.defaultProps = {
  onClick: () => { },
  buttonType: 'submit',
  disabled: false,
  light: false,
  full: false,
};

export default ButtonNext;
