import { motion } from 'framer-motion';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import EditStep from '../components/buttons/edit-step';
import MiniCompany from '../components/mini-company';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { createJobSchema } from '../lib/schema';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { isCheckout, Params } from '..';
import { updateJobV2 } from '../../../utilities/api_v2/job_create';
import { useCheckoutStore } from '../../checkout-v2/lib/store/checkout';

const StepThreeOne = () => {
  const { job_id, is_duplicate }: Params = useParams()
  const {
    selectedCompany,
    setFetchedDatasIsLoading,
    setConfirmJobPostData,
    fetchedDatas: { categories, industries },
  } = useCreateJobStore();
  const { setCommonJob } = useCheckoutStore()
  const { getValues, setValue } = useFormContext<z.infer<typeof createJobSchema>>()
  const commonJob = getValues()
  const history = useHistory()

  const _jobPostHandler = async () => {
    const job = getValues()
    const job_id = job.id
    let data = {
      ...job,
      visible: false,
    }

    localStorage.setItem('visible', data.visible.toString());

    delete data.city_name;
    if (job_id) {
      if (isCheckout(job)) {
        await _updateJob({ ...data })
        setValue("old_referral_count", job.old_referral_count)
        setValue("old_referral_fee", job.old_referral_fee)
        setValue("isNewJob", job.transactions.length === 0)
        setValue("referral_fee", data.referral_fee)
        setCommonJob(() => {
          return {
            ...job,
            old_referral_count: job.old_referral_count,
            old_referral_fee: job.old_referral_fee,
            isNewJob: job.transactions.length === 0,
            referral_fee: job.referral_fee
          }
        })
        history.push({
          pathname: job.blue_collar !== "blue_collar" ? `/checkout/4/0/${job_id}` : `/checkout/4/0/${job_id}`,
          state: { prev: `/create-job/3/0/${job_id}` },
        })
      } else {
        await _updateJob({ ...data })
        history.push(`/create-job/3/1/${job_id}`)
      }
    } else {
      setConfirmJobPostData(true)
    }
  }

  const _updateJob = async (data: z.infer<typeof createJobSchema>) => {
    setFetchedDatasIsLoading(true);
    await updateJobV2(data).finally(() => setFetchedDatasIsLoading(false))
  }

  const fields = [{
    title: "Job Type",
    url: null,
    hidden: true,
    view: commonJob.blue_collar === "blue_collar" ? "Blue-collar" : "Office Based"
  }, {
    title: "Job Title",
    url: `/create-job/2/1/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.title
  }, {
    title: "Industry",
    url: `/create-job/2/2/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: industries.find((data) => data.id === commonJob.industry_id)?.name || ""
  }, {
    title: "Specialization",
    url: `/create-job/2/3/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: categories.find((data) => data.id === commonJob.job_type_id)?.name || ""
  }, {
    title: "Position Level",
    url: `/create-job/2/4/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.job_position_level
  }, {
    title: "Work Location",
    url: `/create-job/2/5/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.home_based ? 'Home-based' : (
      <div>
        <p className="p-0 pb-1 m-0">{commonJob?.city_name}</p>
        <p>{commonJob?.address}</p>
      </div>
    )
  }, {
    title: "Employment Type",
    url: `/create-job/2/6/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.job_employment_type
  }, {
    title: "Skills",
    url: `/create-job/2/7/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.common_job_skills?.map((skill) => {
      return skill?.value ? (
        <div className="inline px-3 py-1 mr-2 bg-gray-100 border border-gray-300 rounded-full">
          {skill?.value}
        </div>
      ) : <></>
    })
  }, {
    title: "Salary",
    url: `/create-job/2/8/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: `${commonJob.minimum_salary} - ${commonJob.maximum_salary}`
  }, {
    title: "Referral Fee",
    url: `/create-job/2/9/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.referral_fee
  }, {
    title: "Hiring Bonus",
    url: `/create-job/2/10/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.hiring_bonus
  }, {
    title: "Work Experience",
    url: `/create-job/2/11/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.job_experience
  }, {
    title: "Minimum Qualification",
    url: `/create-job/2/12/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.minimum_qualification
  }, {
    title: "Job Description",
    url: `/create-job/2/13/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.description
  }, {
    title: "Head Count",
    url: `/create-job/2/14/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.referral_count
  }, {
    title: "Job Post Privacy",
    url: `/create-job/2/15/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`,
    view: commonJob.visible ? 'Public' : 'Private',
    hidden: !!job_id?.length
  }]

  return (
    <div className="flex flex-col flex-1" style={{ maxHeight: "90%" }}>
      <div
        className="flex-1 overflow-auto"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full max-h-full"
        >
          {/* Selected Company */}
          <EditStep
            title={lang.step_three.title}
            bold={false}
            url={`/create-job/1/0/${job_id ? `${job_id}${is_duplicate ? '/duplicate' : ''}` : ''}`}
          >
            <EditStep.Content>
              {selectedCompany && <MiniCompany {...selectedCompany} />}
            </EditStep.Content>
          </EditStep>

          {
            fields.map((field) => {
              return <EditStep
                title={field.title}
                url={field.url}
                hidden={!!field.hidden}
              >
                <EditStep.Content>
                  {field.view}
                </EditStep.Content>
              </EditStep>
            })
          }
        </motion.div>
      </div>
      <div className="flex flex-col flex-1 border-t">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext
              label="Post This Job"
              buttonType="submit"
              onClick={_jobPostHandler}
            // disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThreeOne;
