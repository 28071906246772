import { useContext, useEffect, useState } from 'react';
import Unavailable from '../../../components/custom/unavailable';
import { Board2Context, BoardContext } from '../../../utilities/context';
import Search from '../../../components/forms/search';
import { SuggestedType } from '../../../utilities/types';
import SeekerProfile from '../board-components/profile';
import Loader from '../../../components/loader/loader';
import { useParams } from 'react-router-dom';
import { getMatchedCandidates, getUserMatchAuthorization } from '../../../utilities/api_v2/kanbanBoard';
import MatchCandidateProfile from '../board-components/match-candidate-profile';
import MatchesUI from '../board-components/match-candidate-list';
type Params = {
  id: undefined | string;
};
const Match = () => {
  const [unavailable, setUnavailable] = useState(true);
  const [loader, setLoader] = useState(false)
  const [data, setData] = useState<[]>([])

   const params: Params = useParams();

  const getAuthorization = async () => {
    const response:any = await getUserMatchAuthorization()
    if(response.data && response.data.matches_access){
      console.log(response.data.matches_access)
      setUnavailable(false)
    }
  }

  const getCandidates = async () => {
    setLoader(true)
    const response:any = await getMatchedCandidates({
      "job_id": Number(params.id),
    })
    if(response.data){
      setData(response.data)
      setLoader(false)
    }
  }

  useEffect(() => {
      (async () => {
        await getAuthorization()
      })();
  }, [])

  useEffect(() => {
    if(!unavailable){
      (async () => {
        await getCandidates()
      })();
    }
}, [unavailable])

  if(unavailable){
    return <div className="h-full">
      <Unavailable />
    </div>
  }

  return (
    //commented for the meantime
    <>
    {loader ?  
      <div className='h-full flex justify-center items-center'>
        <Loader />
      </div>
      :
      <MatchesUI matches={data}/>
      }
    </>
  );
};

export default Match;
