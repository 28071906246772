import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  AiOutlineBell,
  AiOutlinePlus,
  AiOutlineQuestionCircle,
  AiOutlineSearch,
  AiOutlineSetting,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Search from '../forms/search';
import SearchModal from '../search';
import { useHistory } from 'react-router';
import { auth } from '../../utilities/auth';
import { getNotificationCount } from '../../utilities/api_v2/dashboard';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { boardFilterJobsAtom } from '../../utilities/atoms/applicant_board/applicantBoardAtoms';
import { recoilSetter } from '../../utilities/helper';

const SidebarOne = (props: any) => {
  const setFilter = useSetRecoilState(boardFilterJobsAtom);
  const [show, setShow] = useState<boolean>(false);
  const { register, handleSubmit, reset, setFocus } = useForm<any>();
  const [pos, setPos] = useState<number>(0);
  const history = useHistory();
  const [count, setCount] = useState<number>(0);


  const _getNotificationCount = async () => {
    await getNotificationCount({
      employer_id: auth.getId(),
    }).then((data:any)=>{
      setCount(data.data)
    })
  }

  useEffect(() => {
    _getNotificationCount()
  }, []);

  const _onClose = () => {
    setShow(false);
  };

  const _submitHandler = (data: any) => {
    setShow(false);
    reset(); 
    recoilSetter(setFilter, { searchText: data.search })
    history.push({
      pathname: '/applicant-board',
    });
  };

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setFocus('search');
      }, 500);
    }
  }, [show]);

  useEffect(() => {
    if (count > 0) {
      setPos(7);
    }
  }, [count]);

  return (
    <aside
      className="sticky top-0 z-10 w-16 h-screen bg-pj-blue-500"
      style={{ zIndex: 1000 }}
    >
      <SearchModal show={show} onClose={_onClose}>
        <form onSubmit={handleSubmit(_submitHandler)}>
          <Search
            name="search"
            placeholder="Search for a candidate name or job title"
            register={register}
          />
        </form>
      </SearchModal>
      <div className="pt-10 h-3/4">
        <Link to="/dashboard">
          <img
            src="/logo-small.svg"
            className="mx-auto mb-3"
            style={{ width: 26, height: 32 }}
          />
        </Link>
        <div className="mt-10">
          <ul className="flex flex-col items-center justify-center">
            <li
              className="h-10 mb-3 cursor-pointer"
              onClick={() => setShow(true)}
            >
              <AiOutlineSearch
                style={{ width: 20, height: 20 }}
                className="text-white"
              />
            </li>
            <li className="h-10">
              <Link to="/create-job/1/0">
                {' '}
                <AiOutlinePlus
                  style={{ width: 20, height: 20 }}
                  className="text-white"
                />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="h-1/4">
        <ul className="flex flex-col items-center justify-center">
          <li
            className="h-10 mb-3"
            onClick={() => props.setShowNotfication(!props.showNotification)}
          >
            {count > 0 && (
              <div className="relative z-10 float-right w-2 h-2 bg-red-400 rounded-full right-2"></div>
            )}
            <AiOutlineBell
              style={{ width: 20, height: 20, marginLeft: pos }}
              className={`text-white cursor-pointer z-0`}
            />
          </li>
          <li className="h-10 mb-3">
            <Link to="/settings/profile">
              <AiOutlineSetting
                style={{ width: 20, height: 20 }}
                className="text-white"
              />
            </Link>
          </li>
          <li className="h-10 cursor-pointer">
            <Link to="/faqs/create-an-account">
              <AiOutlineQuestionCircle
                style={{ width: 20, height: 20 }}
                className="text-white"
              />
            </Link>
          </li>
        </ul>
      </div>
    </aside>
  );
};

export default SidebarOne;
