import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import CeoModal from '../../../components/job-post/ceo-modal';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { OnClickFunction } from '../../../utilities/types';
import { useFormContext } from 'react-hook-form';
import { z } from 'zod';
import { createJobSchema } from '../lib/schema';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { Params } from '..';
import { useHistory, useParams } from 'react-router-dom';

const StepTwoFour = () => {
  const {
    fetchedDatas: { positions },
    onNext
  } = useCreateJobStore()
  const { control, formState: { errors }, setValue, getValues, trigger } = useFormContext<z.infer<typeof createJobSchema>>()
  const params: Params = useParams()
  const history = useHistory()
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState<string | undefined>();

  const _onClick: OnClickFunction = (
    position: string,
  ) => {
    if (position === 'CEO / VP / Director' && localStorage.getItem('isAdmin') === 'false') {
      setModal(true);
    } else {
      setSelect(position);
      setValue('job_position_level', position);
    }
  };

  const _onNext = async () => {
    const isValid = await trigger("job_position_level")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  useEffect(() => {
    const job_position_level = getValues("job_position_level")
    console.log(job_position_level)
    if (job_position_level) {
      setSelect(job_position_level);
    }
  }, [getValues]);

  return (
    <>
      <CeoModal dialog={modal} setDialog={setModal} />
      <div
        className="flex flex-col flex-1 h-full"
      >
        <InputField
          name="job_position_level"
          label=""
          control={control}
          error={errors?.job_position_level}
          type="hidden"
          defaultValue={select}
          rules={{ required: true }}
        />
        <div className="flex flex-initial p-4 border-b">
          <p className="p-0 m-0 text-base text-pj-blue-100">
            {lang.step_two.select_level}
          </p>
        </div>
        <div
          className="flex-initial overflow-auto h-screen/2"
          id="journal-scroll"
        >
          <motion.div
            variants={stagger}
            initial="initial"
            animate="animate"
            className="w-full overflow-x-hidden overflow-y"
            id="journal-scroll"
          >
            <div
              className="flex flex-col flex-1 overflow-x-hidden"
              id="journal-scroll"
            >
              <div className="overflow-y">
                {positions.map(({ label, value, referral_amount }, index) => {
                  return (
                    <div
                      className={`p-4 py-6 text-pj-blue-300 flex flex-initial cursor-pointer ${select === value && 'bg-pj-green-100'
                        }`}
                      onClick={() => _onClick(value, referral_amount)}
                      key={`${index}`}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>
          </motion.div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="flex flex-row items-end flex-1 p-4">
            <div className="flex items-end justify-start w-1/2">
              <ButtonExit />
            </div>
            <div className="flex items-end justify-end w-1/2">
              <ButtonNext onClick={async () => await _onNext()} disabled={!select} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StepTwoFour;
