import ButtonNext from '../../../components/buttons/button-next';
import { lang } from '../../../lang';
import { useCheckoutStore } from '../lib/store/checkout';
import { Controller, useForm, useFormContext, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { checkoutJobSchema, eWalletSchema } from '../lib/schema';
import { computer } from '../lib/services';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker, Form, Input } from 'antd';
import moment from 'moment';
import { updateJobV2 } from '../../../utilities/api_v2/job_create';
import { insertTransaction } from '../../../utilities/api_v2/checkout';
import { auth } from '../../../utilities/auth';
import { Link, useHistory } from 'react-router-dom';
import { xenditInvoice } from '../../../utilities/api_v2/payment/xendit';
import { useState } from 'react';

const StepFourTwo = () => {
    const [loading, setLoading] = useState(false);
    const form = useFormContext<z.infer<typeof checkoutJobSchema>>()
    const { commonJob, setCheckoutJobDataIsLoading, setCheckoutData, checkoutData } = useCheckoutStore()
    const { payment_type } = form.watch()
    const history = useHistory()
    const { overAllTotal, vat, postingFeeSubtotal, adminFee } = computer(form.getValues())

    const eWalletForm = useForm({
        resolver: zodResolver(eWalletSchema),
        defaultValues: {
            e_wallet: "gcash"
        },
    });

    const payment_methods = [
    // {
    //     label: "Debit Card",
    //     sub_label: `You'll be redirected to Xendit's payment gateway after checkout.`,
    //     value: "debit_card",
    //     icon: <div className='flex flex-row items-center gap-1 '>
    //         <img src='/logo-visa.png' alt='Visa logo' className='object-cover h-6 border rounded shadow-sm w-9' />
    //         <img src='/logo-credit-card.png' alt='Credit card logo' className='object-cover h-6 p-1 border rounded shadow-sm w-9' />
    //         <img src='/logo-jbc.png' alt='JBC logo' className='object-cover h-6 border rounded shadow-sm w-9' />
    //         <img src='/logo-american-express.png' alt='American express logo' className='object-cover h-6 border rounded shadow-sm w-9' />
    //     </div>
    // }, 
    // {
    //     label: "E-Wallets",
    //     value: "e_wallets",
    //     element: <EWallets eWalletForm={eWalletForm} />
    // }, 
    {
        label: "Bank Transfer",
        value: "bank_transfer",
        element: <BankTransferElement />
    }, {
        label: "Send Bill",
        value: "send_bill",
        element : <SendBillElement/>
    }] as {
        label: string; sub_label: string; value: 'debit_card' | 'e_wallets' | 'qr_ph' | 'bank_transfer' | 'send_bill', element?: JSX.Element; icon?: JSX.Element
    }[]

    const paymentMethod =(payment_type: string)=> {
        let method = []
        console.log(payment_type, 'paymentMethod')
        switch(payment_type){
          case "maya":
            method = ["PAYMAYA"]
            break;
          case "qr":
            method = ["QRPH"]
            break;
          case "grab":
            method = ["GRABPAY"]
            break;
          case "cebuana":
            method = ["CEBUANA"]
            break;
          case "lbc":
              method = ["LBC"]
              break;
          case "debit_card":
            method = ["DD_BPI", "DD_UBP", "DD_RCBC"]
            break;
          default:
            return {success:false, message:"No payment method found"}
        }
      
        return method
      }

    const integrateXendit = async () => {
         const payload = {
            job_id: form.getValues("id"),
            paymentMethods: paymentMethod(payment_type),
            total_commission: 0,
            total_vat: vat.toFixed(2),
            posting_fee: postingFeeSubtotal,
            admin_fee: adminFee,
            amount: overAllTotal,
            employer_id: auth.getId(),
            payment_type,
            transaction_id: `${moment().unix()}`,
        }
        console.log(payload, 'payload')

        const response:any = await xenditInvoice(payload)
        
        if(response.data.id){
            window.location.href = response.data.invoiceUrl
        }
    }

    const onSubmit = async () => {
        if(payment_type === 'e_wallets' || payment_type === 'debit_card') {
            await integrateXendit()
            return
        }
        console.log(payment_type, 'payment_type')
        const formValues = form.getValues();
        setCheckoutJobDataIsLoading(true)
        console.log(form.getValues(), commonJob)
        const job = {
            //changes from form
            id: formValues.id,
            referral_count: formValues.referral_count,
            referral_fee: formValues.referral_fee,
            hiring_bonus: formValues.hiring_bonus,

            //common jobs details
            title: commonJob.title,
            description: commonJob.description,
            job_employment_type: commonJob.job_employment_type,
            job_position_level: commonJob.job_position_level,
            maximum_salary: commonJob.maximum_salary,
            minimum_salary: commonJob.minimum_salary,
            province_id: commonJob.province_id,
            job_type_id: commonJob.job_type_id,
            industry_id: commonJob.industry_id,
            employer_id: commonJob.employer_id,
            company_detail_id: commonJob.company_detail_id,
            minimum_qualification: commonJob.minimum_qualification,
            job_experience: commonJob.job_experience,
            home_based: commonJob.home_based,
            common_job_skills: commonJob.common_job_skills,
            city_id: commonJob.city_id,
            address: commonJob.address,
            show_salary: commonJob.show_salary,
            visible: commonJob.visible,
            disbursement_type: commonJob.disbursement_type,
        }

        const transaction = {
            total_commission: 0,
            total_vat: vat,
            posting_fee: postingFeeSubtotal,
            admin_fee: adminFee,
            amount: overAllTotal,
            employer_id: auth.getId(),
            payment_type,
            transaction_id: `${moment().unix()}`,
            job_id: commonJob.id
        }

        const data = await Promise.all([
            updateJobV2(job),
            insertTransaction(transaction)
        ]).then((data) => {
            setCheckoutJobDataIsLoading(false)
            return data
        })
        const newTransaction: any = data[1]
        setCheckoutData({
            ...checkoutData,
            transaction_id: newTransaction?.data?.transaction_id,
            created_at: newTransaction?.data?.created_at
        })

        history.push(`/checkout/4/3/${commonJob.id}`);
    }

    return (
        <div className="flex flex-col h-full" >
            <div className="flex flex-row justify-between w-full p-5 text-xl font-semibold border-t border-b text-pj-blue-300">
                <p className='flex-1 '>TOTAL AMOUNT</p>
                <p>{lang.formatString(
                    lang.general.php_total,
                    (overAllTotal).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                    })
                )}</p>
            </div>
            <div className='flex flex-col max-h-full py-5 overflow-y-scroll' id='journal-scroll' >
                <p className='px-5 pb-3 text-lg text-pj-blue-100'>Payment method</p>
                <div className='flex flex-col'>
                    {payment_methods.map((method) => {
                        return <div key={method.value} className='flex flex-col '>
                            <label
                                className={`flex flex-row w-full justify-between items-center cursor-pointer border-gray-200 px-5 `}
                            >
                                <div className='flex flex-row items-center w-full gap-3'>
                                    <div className="flex items-center justify-center h-full">
                                        <input
                                            type="radio"
                                            {...form.register("payment_type")}
                                            value={method.value}
                                        />
                                    </div>
                                    <div className='flex flex-row items-center justify-between flex-1 h-14'>
                                        <div className='flex flex-col '>
                                            <p className=' text-pj-blue-100'>{method.label}</p>
                                            {method.sub_label && <p className='-mt-1 text-xs text-gray-350'>{method.sub_label}</p>}
                                        </div>
                                        {method.icon}
                                    </div>
                                </div>
                            </label>
                            {payment_type === method.value && <div>
                                {method.element}
                            </div>}
                        </div>
                    })
                    }
                </div>
            </div>
            <div className='flex items-end justify-between flex-1 w-full p-8 py-6'>
                <Link to={`/checkout/4/1/${form.getValues("id")}`}>
                    <div
                        className={`px-8 py-2 rounded-full cursor-pointer inline-block text-base bg-gray-100 text-pj-blue-100 border border-gray-200`}
                    >
                        Back
                    </div>
                </Link>
                <ButtonNext onClick={onSubmit} label={"Checkout"} />
            </div>
        </div >
    );
};

const EWallets = ({ eWalletForm }: { eWalletForm: UseFormReturn<z.infer<typeof eWalletSchema>> }) => {
    const {
        watch
    } = eWalletForm
    const activeEWallet = watch("e_wallet")
    const eWallets = [{
        label: "GCash",
        value: "gcash",
        sub_label: "You'll be redirected to Xendit's payment gateway after checkout."
    },]
    return <div className='flex flex-col'>
        {eWallets.map((method) => {
            return <label
                className={`flex flex-row w-full justify-between items-center p-2 h-14 pl-16 cursor-pointer border-gray-200 px-5 ${method.value === activeEWallet && 'bg-green-100'}`}
            >
                <div className='flex flex-row items-center w-full gap-2'>
                    <div className="flex items-center justify-center h-full">
                        <input
                            type="radio"
                            {...eWalletForm.register("e_wallet")}
                            value={method.value}
                        />
                    </div>
                    <div className='flex flex-row items-center justify-between w-full px-2'>
                        <div className='flex flex-col '>
                            <p className=' text-pj-blue-100'>{method.label}</p>
                            {method.sub_label && <p className='-mt-1 text-xs text-gray-350'>{method.sub_label}</p>}
                        </div>
                        <img src='/logo-gcash.png' alt='Gcash logo' className='h-10' />
                    </div>
                </div>
            </label>
        })
        }
    </div>
}

const BankTransferElement = () => {
    return <div className='px-3 py-2 text-sm bg-green-100 text-pj-blue-100'>
        <div className="p-4 bg-gray-100">
            <div className="text-sm ">
                1. Send payment to the following account.
            </div>
            <div className="pt-3 text-sm font-semibold ">
                UnionBank Checking Account
            </div>
            <div className="text-sm ">
                <span className="font-semibold">Account Name:</span> WE SEARCH @ SEARCHERS AND STAFFERS CORP
            </div>
            <div className="text-sm ">
                <span className="font-semibold">Checking Account No.:</span> 0021 7001 2807
            </div>
            <div className="pt-3 text-sm ">
                2. Email the following to info@pasajob.com:
                <div className="flex flex-col ml-4">
                    <span>
                        a. Proof of payment (screenshot of bank transfer/deposit slip)
                    </span>
                    <span>
                        b. transaction ID number
                    </span>
                </div>
            </div>
            <div className="pt-3 text-sm ">
                3. Once confirmed, the amount will be credited to your wallet within the next working day.
            </div>
        </div>
    </div>
}

const SendBillElement = () => {
    return <div className='px-3 py-2 text-sm bg-green-100 text-pj-blue-100'>
        <div className="p-4 bg-gray-100">
            <div className="text-sm ">
                If you have an active contract with us, your job will be posted by the next working day.
            </div>
            <div className="text-sm ">
                If you don't have a contract yet, please reach out to us at <a href='#'>team@pasajob.com</a>.
            </div>
        </div>
    </div>
}

export default StepFourTwo;
