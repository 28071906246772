import { Link, useHistory } from 'react-router-dom';
import Button from '../../../components/buttons/button';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { useCheckoutStore } from '../lib/store/checkout';
import { checkoutJobSchema } from '../lib/schema';
import { z } from 'zod';
import { Controller, useFormContext } from 'react-hook-form';
import { Checkbox } from 'antd';
import ButtonNext from '../../job-create-v2/components/buttons/button-next';
import { computer } from '../lib/services';

const StepFourOne = () => {
  const { getValues, control, watch } = useFormContext<z.infer<typeof checkoutJobSchema>>()
  const { checkoutData } = useCheckoutStore()
  const location = useHistory();
  const values = getValues()

  const { postingFeeSubtotal, adminFee, subTotal, vat, overAllTotal } = computer({ ...values })
  const table: { title: string; value: string }[] =  [
    {
      title: "Posting Fee Subtotal",
      value: lang.formatString(
        lang.general.php_total,
        (postingFeeSubtotal).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string
    },
    {
      title: "Admin Fee (20%)",
      value: lang.formatString(
        lang.general.php_total,
        (adminFee).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string
    },
    {
      title: "Sub Total",
      value: lang.formatString(
        lang.general.php_total,
        (subTotal).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string
    },
    {
      title: "VAT (12%)",
      value: lang.formatString(
        lang.general.php_total,
        (vat).toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string
    },
  ];

  return !checkoutData ? <></> : (
    <div className="flex flex-col h-full p-6 px-8">
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-base font-medium capitalize text-pj-green-200">
          New Job
        </div>
        <div className="w-1/2 text-lg font-semibold text-right text-pj-blue-500">
          {lang.formatString(
            lang.step_four.amount,
            (overAllTotal).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className="flex flex-col items-start w-full gap-5 mt-4 rounded-lg">
        <div
          className={`w-24 h-24 bg-cover rounded-full bg-left flex justify-center items-center text-gray-500 ${(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
            }`}
          style={{ backgroundImage: `url(${checkoutData.company_image})` }}
        >
          {(!checkoutData.company_image || checkoutData.company_image.length <= 0) &&
            abbreviate(checkoutData.company_name ?? '')}
        </div>
        <div className="flex flex-row gap-1 text-lg font-semibold text-pj-blue-500"><div>{checkoutData.job_title}</div> - <div>{checkoutData.company_name}</div></div>
      </div>
      <div className="w-full h-1 my-4 bg-pj-blue-500"></div>
      {table.map((val, index) => {
        //return for empty col in table to remove padding
        if (!val) { return <></> }
        return (
          <div className="flex flex-row mb-2 space-between" key={index}>
            <div className="w-1/2 text-base text-pj-blue-100">{val.title}</div>
            <div className="w-1/2 text-base text-right text-pj-blue-100">
              {val.value}
            </div>
          </div>
        );
      })}
      <div className="w-full h-1 my-4 bg-pj-blue-500"></div>
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-lg font-semibold text-pj-blue-500">
          {lang.step_four.total_amount}
        </div>
        <div className="w-1/2 text-lg font-semibold text-right text-pj-blue-500">
          {lang.formatString(
            lang.step_four.amount,
            (overAllTotal).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className='max-h-full p-4 mt-3 overflow-y-scroll border rounded bg-gray-50' id='journal-scroll'>
        <p className='text-sm font-semibold text-pj-blue-100'>Terms & Conditions</p>
        <div className='px-5 '>
          <ul className='mt-2 text-sm font-normal list-disc text-pj-blue-100'>
            <li>Each job post is provided a 30-day exposure (subject to 1 reposting for another 30 days) and shall be self-posted by the Client/Company through the PasaJob Employer site:  employer.pasajob.com; or may be posted for the Client/Company by PasaJob team.</li>
            <li>The listing fee includes a hiring bonus and referral fee per vacancy. The hiring bonus shall instantly be credited to the hired candidate’s wallet, while the referral fee shall be credited to the referrer/s 30 days after tagging the candidate as Hired—which is defined as having signed a contract with Company or has started work, whichever comes first. </li>
            <li>The listing fee is charged per job post and is non-refundable and non-convertible, in the event that the job post does not result to a hire.</li>
            <li>To promote the job posts, the Company authorizes PasaJob to use its name/brand in all of PasaJob's advertisements and campaigns, including but not limited to online media, email blast, print media, outdoor ads, radio, website ads, etc.</li>
            <li>VAT-exempt and Zero-rated companies are required to send a VAT-Exempt BIR Certificate; otherwise, regular rates will apply.</li>
            <li>In case of conflict, the parties unconditionally consent to resolve the controversy by submitting to the sole and exclusive jurisdiction of the proper and competent arbitration tribunal constituted by the Philippine Dispute Resolution Center Inc. (PDRCI) at Taguig City, following the center’s requirements, rules, and procedure, to the exclusion of all others, and shall be governed and interpreted under Philippine laws. In case of doubt, any interpretation shall be in favor of arbitration as the intended mode of conflict resolution.</li>
            <li>The above terms are further subject to our Terms of Use via: <a target='_blank' className=' text-pj-green-200' href="https://www.pasajob.com/terms-of-use" rel="noreferrer">https://www.pasajob.com/terms-of-use</a></li>
          </ul>
        </div>
      </div>
      <Controller
        name="agreed"
        control={control}
        render={({ field }) => (
          <Checkbox
            checked={field.value}
            className='mt-2 text-pj-blue-100'
            onChange={(e) => field.onChange(e.target.checked)}
          >
            I agree to all the terms and conditions stated above.
          </Checkbox>
        )}
      />
      <div className="flex flex-row items-end self-end justify-between flex-1 w-full pb-16 mt-5">
        <Link to={`/checkout/4/0/${values.id}`}>
          <div
            className={`px-8 py-2 rounded-full cursor-pointer inline-block text-base bg-gray-100 text-pj-blue-100 border border-gray-200`}
          >
            Back
          </div>
        </Link>
        <ButtonNext disabled={!watch("agreed")} onClick={() => {
          location.push({
            pathname: `/checkout/4/2/${values.id}`,
            state: { prev: location.location.pathname },
          })
        }} label={lang.step_four.continue_to_payment} />
      </div>
    </div>
  );
};

export default StepFourOne;


