import axios from 'axios';
import CryptoJS from 'crypto-js';
const authorization =
  'Basic Z2Nhc2hhcHA6eXBlbVlzUWg4WlNmN2RPZ053TFhEaW4zYk1RSTFDTnU=';

export const auth_api = axios.create({
  // baseURL: 'https://auth.gjobs.pasajob.com/employer/',
  baseURL: 'https://auth.api.pasajob.com/employer/',
  // baseURL: 'http://localhost:5000/employer/',
  headers: {
    Authorization: authorization,
    'Content-Type': 'application/json',
  },
});

export const employer_api = axios.create({
  // baseURL: 'https://beta.gjobs.pasajob.com/employer/',
  // baseURL: 'http://localhost:5011/employer/',
  baseURL: 'https://info.api.pasajob.com/employer/',
  headers: {
    Authorization: authorization,
    'Content-Type': 'application/json',
  },
});

export const employer_api_v2 = axios.create({
  // baseURL: 'https://beta.gjobs.pasajob.com/employer_v2/',
  // baseURL: 'http://localhost:5011/employer_v2/',
  baseURL: 'https://info.api.pasajob.com/employer_v2/',
  headers: {
    Authorization: authorization,
    'Content-Type': 'application/json',
  },
});

export const payment_url = axios.create({
  // baseURL: 'http://localhost:5111/',
  baseURL: 'https://payment.api.pasajob.com/',
  
  headers: {
    Authorization: authorization,
    'Content-Type': 'application/json',
  },
});

export const email_api = axios.create({
  baseURL: 'https://email.api.pasajob.com/employer/',
  // baseURL: 'http://localhost:5020/employer',
  headers: {
    Authorization: authorization,
    'Content-Type': 'application/json',
  },
});

export const encryptPayload = val => {
  return CryptoJS.AES.encrypt(val, '@m&#oJECePgX&tbr!tc@unsn8*A6b3').toString();
};
