import { email_api } from "../main";

type inviteToApplyType = {
  job_id: number;
  seeker_id: number;
}

export const inviteToApply = async (data: inviteToApplyType ) => {
    let response = {};

    await email_api.post('invite-to-apply', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
  
        return response
  };