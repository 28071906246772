import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ButtonExit from '../components/buttons/button-exit';
import ButtonNext from '../components/buttons/button-next';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { useFormContext } from 'react-hook-form';
import { useCreateJobStore } from '../lib/store.ts/job-create';
import { createJobSchema } from '../lib/schema';
import { z } from 'zod';
import { Params } from '..';
import { useHistory, useParams } from 'react-router-dom';

const StepTwoTwo = () => {
  const { fetchedDatas: { industries }, onNext } = useCreateJobStore()
  const params: Params = useParams()
  const history = useHistory()
  const [select, setSelect] = useState<number | null>(null);
  const { setValue , trigger, getValues } = useFormContext<z.infer<typeof createJobSchema>>()

  const _onNext = async () => {
    const isValid = await trigger("industry_id")
    if (isValid) {
      const route = onNext(params)
      history.push(route)
    }
  }

  useEffect(() => {
    if (getValues("industry_id")) {
      setSelect(getValues("industry_id"))
    }
  }, [getValues])


  const onChange = async (industy_id: number) => {
    setValue("industry_id", industy_id);
    setValue("job_type_id", undefined);
    setSelect(industy_id)
  }
  
  return (
    <div
      className="flex flex-col flex-1 h-full"
    >
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_industry}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
        >
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <div className="overflow-y">
              {
                industries.map((ind) => {
                  return <label key={ind.id}
                    className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${ind.id === select && 'bg-pj-green-100'}`}
                    onClick={async () => await onChange(ind.id)}
                  >
                    <div className="w-full" style={{ marginTop: -6 }}>
                      <p className="p-0 py-3 m-0 text-pj-blue-500">{ind.name}</p>
                    </div>
                  </label>
                })
              }
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext onClick={async () => await _onNext()} disabled={!select} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepTwoTwo;
