import { auth } from "../../../../utilities/auth";
import { CheckoutJobType } from "../schema";


export const defaultCheckoutData = {
    referral_fee: 0,
    referral_count: 0,
    old_referral_fee: 0,
    old_referral_count: 0,
    processing_fee: 0,
    posting_fee: 0,
    total: 0,
    vat: 0,
    id: 0,
    agreed : false,
    hiring_bonus: 0,
    postingAmount: 0,
    payment_type: "debit_card",
    is_blue_collar : "blue_collar"
} as CheckoutJobType