import { z } from "zod";
import { Category, Employment, Experience, FetchedCompany, Industry, Position, Qualification, CompanyType as Company } from "../../../types/job-create";
import { getEmploymentTypes, getExperiences, getIndustries, getPositions, getQualification, getCompanies, getAllCategories } from "../../../utilities/api_v2/job_create";
import { auth } from "../../../utilities/auth";
import { checkoutJobSchema } from "./schema";

const _getCompanies = async () => {
    return await getCompanies({ employer_id: auth.getId(), }).then(({ data }: { data: FetchedCompany[] }) => {
        if (data) {
            return data.map(
                (data) => {
                    return ({
                        name: data.name,
                        id: data.id,
                        logo: data.company_image,
                        total_jobs: data.total_jobs.aggregate.count,
                        address: data.address,
                        city_id: data.city_id,
                        province_id: data.province_id,
                        city_name: data.common_location_city.name,
                        province_name: data.common_location_province.name,
                        erp_owner: data.common_users.erp_owner
                    })
                }
            ) as Company[];
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

const _getIndustries = async () => {
    return await getIndustries().then((ind: any) => {
        if (ind) {
            let items: Industry[] = ind.data.map(
                ({ id, industry_name }) => ({ id, name: industry_name })
            );
            const other = [...items].filter(({ name }) => name === 'Others');
            items = [...items].filter(({ name }) => name !== 'Others');
            items = [...items, ...other];
            return items
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

const _getPositions = async () => {
    return await getPositions().then((p) => {
        if (p) {
            return p.data.map(
                ({ label, value, referral_amount }) => ({ label, value, referral_amount })
            ) as Position[]
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

const _getEmploymentTypes = async () => {
    return await getEmploymentTypes().then((e) => {
        if (e) {
            return e.data.map(
                ({ label, value }) => ({ label, value })
            ) as Employment[]
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

const _getExperiences = async () => {
    return await getExperiences().then((exp) => {
        if (exp) {
            return exp.data.map(
                ({ label, value }) => ({ label, value })
            ) as Experience[]
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

const _getQualifications = async () => {
    return await getQualification().then((q) => {
        if (q) {
            return q.data.map(
                ({ label, value }) => ({ label, value })
            ) as Qualification[]
        } else {
            return []
        }
    }).catch(e => {
        console.log(e);
        return []
    })
}

export const _getAllCategories = async () => {
    return await getAllCategories().then((cat) => {
        let items: Category[] = cat.data?.map(
            ({ id, job_type_name, industry_id }) => ({ id, name: job_type_name, industry_id })
        ) || [];
        const others = [...items].filter(({ name }) => name === 'Others');
        items = [...items].filter(({ name }) => name !== 'Others');
        return [...items, ...others];
    }).catch(e => {
        console.log(e);
        return []
    })
}

export const _fetchCreateJobDatas = async () => {
    try {
        return await Promise.all([
            _getCompanies(),
            _getIndustries(),
            _getPositions(),
            _getEmploymentTypes(),
            _getExperiences(),
            _getQualifications(),
            _getAllCategories()
        ]).then((data) => {
            return ({
                companies: data[0],
                industries: data[1],
                positions: data[2],
                employment_types: data[3],
                experiences: data[4],
                qualifications: data[5],
                categories: data[6],
            })
        })
    } catch (e) {
        throw e
    }
}

export const computer = ({ referral_fee, hiring_bonus, referral_count, gcash_affiliate, blue_collar }: z.infer<typeof checkoutJobSchema>) => {
    const default_hiring_bonus = blue_collar === "blue_collar" ? 100 : 500
    const default_referral_fee = blue_collar === "blue_collar" ? 100 : 500

    const postingFeeSubtotal = ((referral_fee + hiring_bonus) * referral_count);
    const addOnsReferralFee = referral_fee - default_referral_fee
    const addOnsHiringBonus = hiring_bonus - default_hiring_bonus
    const addOnsTotal = (addOnsHiringBonus + addOnsReferralFee) * referral_count
    const adminFee = (postingFeeSubtotal * .2)
    const subTotal = postingFeeSubtotal + adminFee
    const vat = subTotal * .12
    const overAllTotal = subTotal + vat
    return {
        postingFeeSubtotal,
        addOnsReferralFee,
        addOnsHiringBonus,
        addOnsTotal,
        adminFee,
        subTotal,
        vat,
        overAllTotal
    }
}