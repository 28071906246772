import { motion } from 'framer-motion';
import { FormProvider, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { createJobSchema } from "./lib/schema";
import { useEffect } from "react";
import { useCreateJobStore } from "./lib/store.ts/job-create";
import { Link, useHistory, useParams } from "react-router-dom";
import { _fetchCreateJobDatas } from "./lib/services";
import { AiOutlineClose } from "react-icons/ai";
import JobSteps from './components/step';
import { slideIn } from '../../framer';
import { CircularProgress } from '@material-ui/core';
import MiniCompany from './components/mini-company';
import { Modal } from 'antd';
import { isOnTeam } from '../../utilities/helper';
import { createJobV2, getJobDetailV2 } from '../../utilities/api_v2/job_create';
import { Company } from '../../utilities/types';
import { useCheckoutStore } from '../checkout-v2/lib/store/checkout';
import { defaultCommonJob } from './lib/store.ts/const';

export type Params = {
    step: string;
    sub: string;
    job_id?: string;
    is_duplicate?: string
};

export const isCheckout = (job: z.infer<typeof createJobSchema>) => {
    const { old_referral_fee, old_referral_count, referral_fee, referral_count } = job
    if (!old_referral_fee && !old_referral_count) {
        return true
    } else {
        if (old_referral_fee && old_referral_count && referral_fee &&
            (referral_fee > old_referral_fee || referral_count > old_referral_count)) {
            return true
        } else {
            return false
        }
    }
}

const CreateJob = () => {
    const { step, sub, job_id, is_duplicate }: Params = useParams();
    const isDuplicate = is_duplicate === "duplicate"
    const {
        setFetchedDatas,
        setFetchedDatasIsLoading,
        fetchedDatasIsLoading,
        selectedCompany,
        totalSubStep,
        setSelectedCompany,
        pages,
        confirmJobPostData,
        setConfirmJobPostData,
        setActiveAddress,
        setNewAddresses,
        setWithApplcants
    } = useCreateJobStore()
    const { setCommonJob } = useCheckoutStore()
    const history = useHistory()
    const form = useForm<z.infer<typeof createJobSchema>>({
        resolver: zodResolver(createJobSchema),
        shouldUseNativeValidation: true,
        defaultValues: {
            ...defaultCommonJob
        }
    })

    const _getJob = async (job_id: number) => {
        let payload = {
            id: job_id,
        };
        try {
            await getJobDetailV2(payload).then(({ data }: any) => {
                let newJob: any = data;
                newJob = {
                    ...newJob,
                    ...newJob.information[0],
                    ...newJob.order[0],
                    title: (isDuplicate ? "Copy of " : "") + newJob.title,
                    blue_collar: data.blue_collar ? "blue_collar" : "office_based",
                    company_name: newJob.company.name,
                    company_logo: newJob.company.logo,
                    erp_owner: newJob.company.erp_owner,
                    city_name: newJob?.common_city?.name,
                    address: newJob?.address,
                    common_job_skills: newJob?.common_job_skills
                } as z.infer<typeof createJobSchema>
                let company: any = { ...newJob.company };
                company = {
                    ...company,
                    city_name: company.city_name.name,
                    province_name: company.province_name.name,
                    total_jobs: company.total_jobs.aggregate.count,
                } as Company;

                delete newJob.information;
                delete newJob.order;
                delete newJob.company;
                delete newJob.skills;
                delete newJob?.common_city;
                setSelectedCompany(company);

                form.reset(newJob);

                setActiveAddress(newJob.home_based ? 'home_based' : 'company_address');

                if (company.address !== newJob.address) {
                    setNewAddresses(prev => [...prev, {
                        address: newJob.address,
                        city_id: newJob.city_id,
                        province_id: newJob.province_id,
                        home_based: false,
                        city_name: newJob?.city_name,
                    }])
                    if (!newJob.home_based) {
                        setActiveAddress('0');
                    }
                }

                if (
                    newJob.hired.aggregate.count > 0 &&
                    newJob.transactions.length > 0
                ) {
                    setWithApplcants(true);
                }

                if (!!newJob) {
                    if (isCheckout(newJob)) {
                        console.log(newJob, "checkout")
                        setCommonJob(() => {
                            return {
                                ...newJob,
                                old_referral_count: newJob.old_referral_count,
                                old_referral_fee: newJob.old_referral_fee,
                                isNewJob: newJob.transactions.length === 0,
                                referral_fee: newJob.referral_fee
                            }
                        })
                        // history.push({
                        //     pathname: `/checkout/4/0/${job_id}`,
                        //     state: { prev: `/create-job/3/0/${job_id}` },
                        // });
                    } else {
                        // history.push(`/create-job/3/1/${job_id}`);
                    }
                }
            })
        } catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        (async () => {
            setFetchedDatasIsLoading(true)
            try {
                const createJobDatas = await _fetchCreateJobDatas();
                if (!!job_id) {
                    await _getJob(Number(job_id))
                } else {
                    createJobDatas.companies[0] && setSelectedCompany(createJobDatas.companies[0])
                }
                if (createJobDatas) {
                    setFetchedDatas((prev) => {
                        return ({ ...prev, ...createJobDatas })
                    })
                }
            } catch (e) {
                throw e
            } finally {
                setFetchedDatasIsLoading(false)
            }
        })()
    }, [setFetchedDatas, setFetchedDatasIsLoading, setSelectedCompany])

    useEffect(() => {
        if (parseInt(step) > 1 && !job_id && !selectedCompany) {
            history.push('/create-job/1/0');
        }
    }, [step, sub, job_id]);


    const _createJobPost = async () => {
        const data = form.getValues()
        console.log(data)
        if (data) {
            setFetchedDatasIsLoading(true);
            await createJobV2(data).then((job: any) => {
                setConfirmJobPostData(false)
                const jobId = job.data.id
                setCommonJob(() => {
                    return {
                        ...data,
                        id: jobId,
                        isNewJob: true,
                    }
                })
                if (isCheckout(data)) {
                    history.push({
                        pathname: `/checkout/4/0/${jobId ? `${jobId}${isDuplicate ? "/duplicate" : ""}` : ''}`,
                        state: {
                            prev: `/create-job/3/0/${jobId ? `${jobId}${isDuplicate ? "/duplicate" : ""}` : ''}`,
                        },
                    });
                } else {
                    history.push(
                        `/create-job/3/1/${jobId ? `${jobId}${isDuplicate ? "/duplicate" : ""}` : ''}`
                    );
                }
            }).catch((e) => {
                console.log(e);
            }).finally(() =>
                setFetchedDatasIsLoading(false))
        }
    }

    const ActivePage = () => {
        return pages()[step][sub].element
    }

    const ActiveHeader = () => {
        return (
            <div className="flex p-4">
                <div className="flex-auto text-xl text-gray-800">
                    {totalSubStep(step) > 1 && (
                        <div className="w-full h-2 mt-1 mb-4 bg-opacity-25 rounded-full bg-pj-green-200">
                            <div
                                className="h-full rounded-full bg-pj-green-200"
                                style={{ width: `${((parseInt(sub) + 1) / totalSubStep(step)) * 100}%` }}
                            ></div>
                        </div>
                    )}
                    {pages()[step][sub].title ? pages()[step][sub].title : ""}
                </div>
                <div
                    className={`w-10 transparent flex items-start justify-center 
                        ${totalSubStep(step) > 1 ? 'items-start' : 'items-center'}
                    `}
                >
                    <Link to="/dashboard">
                        <AiOutlineClose className="text-xl text-pj-blue-100" />
                    </Link>
                </div>
            </div>
        )
    }

    return (
        <motion.div
            variants={slideIn}
            className="fixed flex items-center justify-center w-full h-full bg-pj-blue-500"
        >
            {/* <JobPostConfirmation/> */}
            <Modal closable={false} style={{ top: "50%", transform: "translate(10%, -50%)", }} title="This service is temporarily unavailable." open={!isOnTeam()} footer={[]}>
                <div className="mt-2">
                    As mentioned in our latest email regarding all-in pricing, our platform will undergo maintenance as we implement important updates.
                    If you would like to post a job during this period, please get in touch with our sales team at team@pasajob.com.
                    Thank you!</div>
                <div className='flex justify-end gap-2 '>
                    <Link to="/dashboard">
                        <button className='px-5 py-2 mt-5 text-white rounded cursor-pointer bg-pj-green-200'>
                            Go Back
                        </button>
                    </Link>
                </div>
            </Modal>
            <Modal style={{ top: "50%", transform: "translate(10%, -50%)", }} title="Job Post Privacy" open={!!confirmJobPostData} footer={[]} closable={false}>
                <div>You can't change the visibility of the job post once you submit. Would you like to proceed?</div>
                <div className='flex justify-end gap-2 '>
                    <button className='px-5 py-2 border rounded cursor-pointer ' onClick={() => setConfirmJobPostData(null)}>Cancel</button>
                    <button className='px-5 py-2 text-white rounded cursor-pointer bg-pj-green-200' onClick={_createJobPost}>Proceed</button>
                </div>
            </Modal>

            <div className='flex flex-col h-full px-2' style={{ width: 700 }}>
                <JobSteps step={step} />

                <motion.div
                    variants={slideIn}
                    animate="animate"
                    initial="initial"
                    className="relative flex flex-col w-full h-full overflow-hidden bg-white rounded-t-lg">
                    {<ActiveHeader />}

                    {
                        fetchedDatasIsLoading &&
                        <div className='absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center bg-black opacity-10' >
                            <CircularProgress style={{ color: "white" }} size={50} />
                        </div>
                    }
                    <FormProvider {...form}>
                        <form
                            className='h-full'
                        >
                            <ActivePage />
                        </form>
                    </FormProvider>

                    {parseInt(step) !== 3 && selectedCompany && (
                        <div className="w-full h-24 bg-white border-t border-gray-200">
                            <div className="p-4">
                                <div className="pb-2 text-base text-pj-blue-500">
                                    You are posting a job for:
                                </div>
                                <MiniCompany {...selectedCompany} />
                            </div>
                        </div>
                    )}
                </motion.div>
            </div>

        </motion.div>
    );
}

export default CreateJob;