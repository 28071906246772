import { Avatar, Image } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { useHistory, useParams } from 'react-router-dom';
import ContactInfo from '../../../candidate-profile/components/contact-info';
import { CircularProgress } from '@material-ui/core';
import { getMatchedCandidateDetails } from '../../../../utilities/api_v2/kanbanBoard';
import { inviteToApply } from '../../../../utilities/api_v2/email/board';

type Params = {
  seeker_id: string;
  job_id: string;
};

type seekerProps = {
  full_name: string;
  profile: string;
  job_title: string;
  email:string;
  mobile: string
  position_level : string,
  industry: string,
  specialization: string,
  experience_level: string,
  status: string
}
export const JobMatchProfile = () => {
  const [loading ,setLoading] = useState(true)
  const [seeker, setSeeker] = useState<seekerProps>()
  const history = useHistory();
  const aboutRef: any = useRef(null);
  const containerRef: any = useRef(null);
  const { seeker_id, job_id }: Params = useParams();

  const RedactString = ( text, visibleChars = 8 ) => {
    if (!text || visibleChars < 0 || visibleChars > text.length) {
      return text; // Return original text if parameters are invalid
    }
  
    const redactedPart = "*".repeat(text.length - visibleChars);
    const redactedText = text.substring(0, visibleChars) + redactedPart;
  
    return redactedText;
  };

  const getDetails = async () => {
    const response:any = await getMatchedCandidateDetails({ seekerId: Number(seeker_id), job_id: Number(job_id) })
    if(response.data) {
      setSeeker(response.data)
      setLoading(false)
    }
    console.log(response)
  }

  const getInitials = (name: string) => {
    return name.substring(0, 2).toUpperCase()
  }

  const handleInvite = async () => {
      const payload = {
        job_id: Number(job_id),
        seeker_id: Number(seeker_id),
      }
  
      await inviteToApply(payload).then((data) => {
        getDetails()
      })
    }

  useEffect(() => {
    if(seeker_id && job_id){
      (async () => {
        await getDetails()
      })();
    }
  }, [seeker_id, job_id])

  if (loading) {
      return <div className="flex items-center justify-center h-full pl-8 bg-pj-green-100">
        <div className='flex items-center justify-center w-full h-full bg-gray-50'>
          <CircularProgress style={{ color: "#afdfd8" }} size={50} />
        </div>
      </div>
    }
  return (
    <div className="grid min-h-screen grid-cols-3 gap-3 bg-pj-green-100">
      <div className="flex flex-col w-full pl-8 border-l-2 border-r-2 flex-between">
        <div className="w-full h-full bg-white">
          <div className="relative w-full mt-2 p-4 border-b">
            <AiOutlineArrowLeft
              className="text-xl cursor-pointer text-pj-blue-500 mb-4"
              onClick={() => history.goBack()}
            />
            <div className="absolute" style={{ top: 30 }}>
            <Avatar size={100} >
            {getInitials(seeker.full_name)}
            </Avatar>
            </div>
          </div>
          <div className="px-4 pt-20 pb-4 border-b">
            <div className="text-lg font-semibold text-pj-blue-500">
              {seeker.full_name}
            </div>
            <div className="text-base text-pj-blue-100">
              {seeker.job_title}
            </div>
          </div>
          <div className="flex flex-row items-center justify-center w-full p-4 border-b">
            <div className='w-full'>
              <ContactInfo type="number" info={'**********'} />
              <ContactInfo type="email" info={'**********'} />
              {/* <ContactInfo type="linkedin" info={props.linkedin_url} /> */}
            </div>
            <div className="">
              {/* {props.status && ['Rejected', 'Applied'].includes(props.status) && (
                <Tooltip
                  arrow={false}
                  color={"#1c4464"}
                  title={<>
                    <div className="mb-2 text-sm font-semibold">
                      See Contact Details
                    </div>
                    <div>
                      Move applicant cards to Pre-screened to view their contact
                      details.
                    </div>
                  </>}
                  key={"question"}
                  placement="bottomRight">
                  <AiOutlineQuestionCircle className="text-gray-400 hover:text-pj-blue-500" size={16} />
                </Tooltip>
              )} */}
            </div>
          </div>
        </div>
        <div className="p-4 bg-white">
          {seeker.status === 'pending' ? 
            <div
              className={`relative flex flex-row justify-center p-2 pl-4 rounded-full cursor-pointer bg-pj-green-200 text-white`}
              onClick={handleInvite}
            >
              <div>Invite to Apply</div>
            </div>
            :
            <div
              className={`relative flex flex-row justify-center p-2 pl-4 rounded-full cursor-pointer bg-gray-200 text-gray-400`}
            >
              <div>{seeker.status}</div>
            </div>
          }
        </div>
      </div>
      <div className="relative h-full col-span-2 bg-white">
        <div style={{ height: 'calc(100vh - 3rem)', overflow: 'initial' }}>
             <div className="w-full max-h-screen">
                        <div className="flex justify-center p-5 font-semibold border-b text-pj-blue-500">
                            PROFILE
                        </div>
                        <div
                            style={{ height: 'calc(100vh - 7rem)', overflow: 'auto' }}
                            id="journal-scroll"
                            ref={containerRef}
                            className='flex flex-col '
                        >
                            <div className="h-auto">
                                <div
                                    className="p-8 py-4 text-gray-400 border-b bg-gray-50"
                                    ref={aboutRef}
                                >
                                    ABOUT
                                </div>
                                <div className="px-8 py-4 text-gray-400 border-b">
                                    <div className="text-base font-semibold capitalize text-pj-blue-500">
                                        current job title
                                    </div>
                                    <div className="text-sm text-pj-blue-100">
                                      {seeker.job_title}
                                    </div>
                                </div>
                                <div className="px-8 py-4 text-gray-400 border-b">
                                    <div className="text-base font-semibold capitalize text-pj-blue-500">
                                        position level
                                    </div>
                                    <div className="text-sm text-pj-blue-100">
                                      {seeker.position_level}
                                    </div>
                                </div>
                                <div className="px-8 py-4 text-gray-400 border-b">
                                    <div className="text-base font-semibold capitalize text-pj-blue-500">
                                        experience level
                                    </div>
                                    <div className="text-sm text-pj-blue-100">
                                      {seeker.experience_level}
                                    </div>
                                </div>
                                <div className="px-8 py-4 text-gray-400 border-b">
                                    <div className="text-base font-semibold capitalize text-pj-blue-500">
                                        industry
                                    </div>
                                    <div className="text-sm text-pj-blue-100">
                                        {seeker.industry}
                                    </div>
                                </div>
                                <div className="px-8 py-4 text-gray-400 border-b">
                                    <div className="text-base font-semibold capitalize text-pj-blue-500">
                                        specialization
                                    </div>
                                    <div className="text-sm text-pj-blue-100">
                                      {seeker.specialization}
                                    </div>
                                </div>
                            </div>
                            <div className='flex-1 w-full max-h-screen py-5 bg-gray-50'>
                                <div className='flex justify-center h-32 text-sm text-pj-blue-100'>
                                    <p className='text-center w-52'>
                                        {/* Move candidate card to Pre-screened to view {props.name.toUpperCase()}'s profile */}
                                        {/* footer copy */}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
      </div>
    </div>
  );
}
