import { useEffect } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Select from '../../../components/forms/select';
import { CompanyAddressInitialState } from '../../../utilities/states';
import {
  CompanyAddress,
  OnClickFunction,
  Option,
} from '../../../utilities/types';
import { getRegions } from '../../../utilities/api_v2/job_create';

type Props = {
  callback: OnClickFunction | undefined;
};

const NewAddress = ({ callback }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setError,
    watch,
    setValue,
    clearErrors,
  } = useForm<any>({ defaultValues: { ...CompanyAddressInitialState } });

  const _getRegions = async () => {
    return await getRegions().then((data)=>{
       if(data){
        setRegions([
          { label: 'Select Region', value: null },
          ...data.data.country[0].regions,
        ])
       }
       return data
   })
}


  const [regions, setRegions] = useState([] as Option[]);
  const [provinces, setProvinces] = useState([
    { label: 'Select Province', value: null },
  ] as Option[]);
  const [cities, setCities] = useState([
    { label: 'Select City', value: null },
  ] as Option[]);

  const watchRegion = watch && watch('region_id');
  const watchProvince = watch && watch('province_id');

  useEffect(() => {
    if (watchRegion) {
      const items = regions.filter(
        ({ value }) => value === parseInt(watchRegion)
      );
      if (setValue) {
        setValue('province_id', null);
        setValue('city_id', null);
      }
      if (items[0]?.children) {
        setProvinces([{ label: '', value: null }, ...items[0].children]);
        setCities([{ label: '', value: null }]);
      }
    }
  }, [watchRegion]);

  useEffect(()=>{
    _getRegions()
  },[])

  useEffect(() => {
    if (watchProvince) {
      let items = regions.filter(
        ({ value }) => value === parseInt(watchRegion)
      );
      if (items[0]?.children) {
        items = items[0].children.filter(
          ({ value }) => value === parseInt(watchProvince)
        );
        if (items[0]?.children) {
          setCities([{ label: '', value: null }, ...items[0].children]);
        }
      }
    }
  }, [watchProvince]);

  const _submitNewAddress = () => {
    if (!getValues('region_id')) {
      setError('region_id', { message: 'Province is required' });
    }

    if (!getValues('province_id')) {
      setError('province_id', { message: 'Province is required' });
    }

    if (!getValues('city_id')) {
      setError('city_id', { message: 'City is required' });
    }

    if (!getValues('address')) {
      setError('address', { message: 'Address is required' });
    }
    if (
      getValues('region_id') &&
      getValues('province_id') &&
      getValues('city_id') &&
      getValues('address') &&
      callback
    ) {
      clearErrors();
      const selectedCity = cities.filter(
        ({ value }) => value === parseInt(getValues('city_id'))
      );
      callback({
        province_id: parseInt(getValues('province_id')),
        city_id: parseInt(getValues('city_id')),
        address: getValues('address'),
        home_base: false,
        city_name: selectedCity?.[0]?.label,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(_submitNewAddress)} className="pt-5">
      <Select
        control={control}
        label=""
        name="region_id"
        rules={{ required: 'Region is required' }}
        error={errors?.region_id}
        options={regions}
        placeholder="Select Region"
      />
      <Select
        control={control}
        label=""
        name="province_id"
        rules={{ required: 'Province is required' }}
        error={errors?.province_id}
        options={provinces}
      />
      <Select
        control={control}
        label=""
        name="city_id"
        rules={{ required: 'City is required' }}
        error={errors?.city_id}
        options={cities}
      />
      <InputField
        control={control}
        name="address"
        label=""
        placeholder="Enter address"
        rules={{ required: 'Address is required' }}
        error={errors?.address}
      />
      <div className="flex justify-end">
        <ButtonNext
          light
          label="Add"
          buttonType="button"
          onClick={_submitNewAddress}
        />
      </div>
    </form>
  );
};

export default NewAddress;
