import { useEffect, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';
import { useCreateJobStore } from '../../lib/store.ts/job-create';

type Props = {
  exit: boolean;
  step?: number;
  sub?: number;
};

type Params = {
  step: string;
  sub: string;
  job_id?: string;
};

const ButtonExit = ({ exit }: Props) => {
  const history = useHistory();
  const { totalSubStep, isEdit } = useCreateJobStore()
  const { step, sub, job_id }: Params = useParams();
  const actualStep = {
    1: totalSubStep("1") - 1,
    2: totalSubStep("2") - 1,
    3: totalSubStep("3") - 1,
  };

  let mainStep = parseInt(step);
  let subStep = parseInt(sub) - 1;

  if (mainStep !== 2 && subStep < 0) {
    mainStep = mainStep - 1;
    subStep = actualStep[mainStep];
  }
  if (mainStep === 2 && subStep < 0) {
    mainStep = mainStep - 1;
    subStep = actualStep[mainStep];
  }

  const url =
    mainStep === 0
      ? '/dashboard'
      : `/create-job/${mainStep}/${subStep}/${job_id ?? ''}`;
  return (
    <div
      className={`${(isEdit && sub !== '3' && step === '2') ? "hidden" : "flex"} flex-row justify-center cursor-pointer text-base `}
      onClick={() => {
        history.push(url)
      }}
    >
      {!exit && (
        <div className="flex items-center justify-center h-10">
          <RiArrowLeftSLine className="text-2xl text-gray-400" />
        </div>
      )}
      <div
        className={`h-10 flex justify-center items-center uppercase text-gray-400 ${exit && 'pl-3'
          }`}
      >
        {exit ? 'Exit' : 'Back'}
      </div>
    </div>
  );
};

ButtonExit.defaultProps = {
  exit: false,
};

export default ButtonExit;
