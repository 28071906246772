import { payment_url } from "../main";

export const xenditInvoice = async (data: any) => {
    let response = {};

    await payment_url.post('xendit/invoice', data)
        .then(res => {
            console.log(res, 'res');
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};