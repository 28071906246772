import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { CompanyType } from '../../../types/job-create';

const MiniCompany = (props: CompanyType) => {
  return (
    <div
      className={`flex w-full flex-row justify-center items-center mb-4 cursor-pointer`}
    >
      <div className="flex items-center justify-center w-8 h-8">
        <div
          className={`w-8 h-8 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xs ${
            (!props.logo || props.logo.length <= 0) &&
            'bg-gray-200 tracking-wide'
          }`}
          style={{ backgroundImage: `url(${props.logo})` }}
        >
          {(!props.logo || props.logo.length <= 0) && abbreviate(props.name)}
        </div>
      </div>
      <div className="flex flex-col justify-center flex-auto">
        <div className="flex-auto p-1 text-sm text-pj-blue-100">
          {lang.formatString(lang.company.company_name, props.name)}
        </div>
      </div>
    </div>
  );
};

export default MiniCompany;
